import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

// Composition API
Vue.use(VueCompositionAPI)

/*import i18n from '@/libs/i18n'*/
import router from './router'
import store from './store'
import App from './App.vue'
import VueAxios from 'vue-axios'
import axios from 'axios'
import Swal from 'sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css';

import * as VueGoogleMaps from 'vue2-google-maps';

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'

import viewer from 'v-viewer'
import VueClipboard from 'vue-clipboard2'

import CKEditor from 'ckeditor4-vue';

import 'v-autocomplete/dist/v-autocomplete.css'
import Autocomplete from 'v-autocomplete'
Vue.use(Autocomplete)

Vue.use( CKEditor );
 
Vue.use(VueClipboard)

Vue.use(viewer);

Vue.use(VueGoogleMaps, {
  
  load: {
    key: 'AIzaSyAKeDjawjCEvrOve8mqI__tr_xA7_zN1f4',
    libraries: 'places',
  },
  installComponents: true
})

import GmapCluster from "vue2-google-maps/dist/components/cluster";
Vue.component('GmapCluster', GmapCluster);

//import moment from 'moment'
var moment = require('moment-timezone');

import VueSignature from "vue-signature-pad";
Vue.use(VueSignature);



// import 'cropperjs/dist/cropper.css';



// Axios Mock Adapter


// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

Vue.use(VueAxios, axios)

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

require('@/assets/scss/vue-select.scss')

window.Swal = Swal;

Vue.config.productionTip = false

Vue.filter('capitalize', function (value) {
 if (!value) return 'N/A'
 value = value.replace(/_/g,' ');
 value = value.toString()
 /*return value.toUpperCase()*/
 return value.charAt(0).toUpperCase() + value.slice(1)
})

Vue.filter('camelCase', function (value) {
 if (!value) return 'N/A'
 value = value.replace(/_/g,' ');

 return value.replace(/_/g, ' ').replace(/\b\w/g, c => c.toUpperCase());
})

Vue.filter('dateTime', function (value) {
	if (!value) return 'N/A'
		
		value = moment(value).tz('Asia/Singapore').format('DD MMM YYYY HH:mm')
		return value
	})

Vue.filter('unixDateTime', function (value) {
	if (!value) return 'N/A'
		
		value = moment(value).format('YYYY-MM-DD HH:mm')
		return value
	})

Vue.filter('dateTime2', function (value) {
	if (!value) return 'N/A'
		
		value = moment(value).format('DD MMM YYYY HH:mm')
		return value
	})

Vue.filter('timeHM', function (value) {
	if (!value) return 'N/A'
		
		value = moment(value).format('HH:mm')
		return value
	})

Vue.filter('date', function (value) {
	if (!value) return 'N/A'

		value = moment(value).tz('Asia/Singapore').format('DD MMM YYYY')
		return value
	})

Vue.filter('dateRangePicker', function (value) {
	if (!value) return ''

		value = moment(value).format('DD MMM YYYY')
		return value
	})

Vue.filter('currency', function (value) {
	if (!value) return 0.00
	 	return parseFloat(value).toFixed(2)
	})

Vue.filter('trim', function (value) {
	value = value.toString()
    if (value.length < 200) {
        return value;
    } else {
        return value.substring(0, 200) + '...';
    }
})

Vue.filter('time', function (value) {
	if (!value) return ''
	
		value = value.substring(0, value.length - 3);
		return value
	})

Vue.filter('timeFormat', function (value) {
	if (!value) return ''
	
		value = moment(value,'HH:mm').format('hh:mm A')
		return value
	})


Vue.filter('eventCal', function (arg) {
	
	if (arg.event._def.extendedProps.durations_in_day == null){ 
		return arg.event.title
	}
	else if (arg.event._def.extendedProps.durations_in_day > 1) {
		return arg.event.title;
	}
	else if(arg.event._def.extendedProps.durations_in_day == 1 && arg.event.title.length >= 20){

		return arg.event.title.substring(0, 20) + '...' ;
	}
	else if(arg.event._def.extendedProps.durations_in_day == 1 && arg.event.title.length < 20){
		return arg.event.title;
	}else{
		return arg.event.title;
	}
})

Vue.filter('dateTimeSec', function (value) {
	if (!value) return 'N/A'
		
		value = moment(value).format('DD MMM YYYY HH:mm')
		return value
	})

Vue.filter('crewArray', function (value) {
	if (value.length > 0) {

		var crew = [];

	    value.forEach(i => {
	      crew.push(i.full_name);
	    })

	    return crew.join(', ');

	}else{
		return 'N/A';
	}
	
})

Vue.filter('shiftArray', function (value) {
	if (value.length > 0) {

		var shift = [];

	    value.forEach(i => {
	      shift.push(i.shift);
	    })

	    return shift.join(', ');

	}else{
		return 'N/A';
	}
	
})

Vue.filter('groupArray', function (value) {
	if (value && value.length > 0) {
		var crew = [];

		value.forEach(i => {
			crew.push(i);
		})
		return crew.join(', ');
	}else{
		return 'N/A';
	}
})

Vue.filter('removeUnderCapitalize',function(value){
	if (value) {
		value = value.replace(/_/g, ' ');

		value = value.split(' ');

		var arr = [];
		value.forEach(item => {
			arr.push(item.charAt(0).toUpperCase() + item.slice(1))
		})

		return arr.join(' ');
	}else{
		return 'N/A';
	}
})

Vue.filter('truncate',function(value){
	if (value.length > 50) {
		return value.substring(0, 50) + "...";
	}else{
		return value;
	}
})

Vue.filter('truncate20',function(value){
	if (value.length > 20) {
		return value.substring(0, 20) + "...";
	}else{
		return value;
	}
})
Vue.filter('truncate11',function(value){
	if (value.length > 11) {
		return value.substring(0, 11) + "..";
	}else{
		return value;
	}
})

Vue.filter('truncate200',function(value){
	if (value.length > 200) {
		return value.substring(0, 200) + "...";
	}else{
		return value;
	}
})

Vue.filter('showReadNum',function(value){
	var obj = new Intl.NumberFormat('en-US',{style:'currency',currency: 'USD'});  
    var result = obj.format(value); 

	return result;
})



Vue.mixin({
	methods: {
		// checkP1: function(index, menus){
		// 	var flag = false;
		// 	var br = false;

		// 	if(menus[index].permissions.length > 0 && br == false){
		// 		for(var j = 0; j < menus[index].permissions.length; j++){
		// 			if(br == false){
		// 				flag = menus[index].permissions[j].access;
		// 				br = flag == true ? true : false;
		// 			}
		// 		}
		// 	} else {
		// 		flag = menus[index].access;
		// 		br = flag == true ? true : false;
		// 	}

		// 	return flag;
		// },
		
		// checkP2: function(index, menus){
		// 	var flag = false;
		// 	var br = false;

		// 	if(br == false){
		// 		for(var j = 0; j < menus[index].children.length; j++){

		// 			if(br == false){
		// 				flag = menus[index].children[j].access;
		// 				br = flag == true ? true : false;

		// 				if(menus[index].children[j].permissions.length > 0 && br == false){
		// 					for(var k = 0; k < menus[index].children[j].permissions.length; k++){
		// 						if(br == false){
		// 							flag = menus[index].children[j].permissions[k].access;
		// 							br = flag == true ? true : false;
		// 						}
		// 					}
		// 				} else {
		// 					flag = menus[index].children[j].access;
		// 					br = flag == true ? true : false;
		// 				}
		// 			}

		// 		}
		// 	}
		// 	return flag;
		// },
		
		// checkP3: function(index, menus){
		// 	var flag = false;
		// 	var br = false;

		// 	if(br == false){
		// 		for(var j = 0; j < menus[index].children.length; j++){

		// 			if(br == false){
		// 				flag = menus[index].children[j].access;
		// 				br = flag == true ? true : false;

		// 				if(menus[index].children[j].hasOwnProperty( 'permissions' )){
		// 					if(menus[index].children[j].permissions.length > 0 && br == false){
		// 						for(var k = 0; k < menus[index].children[j].permissions.length; k++){
		// 							if(br == false){
		// 								flag = menus[index].children[j].permissions[k].access;
		// 								br = flag == true ? true : false;
		// 							}
		// 						}
		// 					} else {
		// 						flag = menus[index].children[j].access;
		// 						br = flag == true ? true : false;
		// 					}
		// 				}

		// 				if(menus[index].children[j].hasOwnProperty( 'children' )){
		// 					for(var l = 0; l < menus[index].children[j].children.length; l++){
								
		// 						if(br == false){
		// 							flag = menus[index].children[j].children[l].access;
		// 							br = flag == true ? true : false;

		// 							if(menus[index].children[j].children[l].permissions.length > 0 && br == false){
		// 								for(var m = 0; m < menus[index].children[j].children[l].permissions.length; m++){
		// 									if(br == false){
		// 										flag = menus[index].children[j].children[l].permissions[m].access;
		// 										br = flag == true ? true : false;
		// 									}
		// 								}
		// 							} else {
		// 								flag = menus[index].children[j].children[l].access;
		// 								br = flag == true ? true : false;
		// 							}
		// 						}

		// 					}
		// 				}
		// 			}

		// 		}
		// 	}
		// 	return flag;
		// },

		// checkC1: function(index, ind, menus){
		// 	var flag = false;
		// 	var br = false;

		// 	var temp = menus[index].children[ind].permissions;

		// 	if(br == false){
		// 		flag = menus[index].children[ind].access;
		// 		br = flag == true ? true : false;

		// 		if(temp.length > 0 && br == false){
		// 			for(var k = 0; k < temp.length; k++){
		// 				if(br == false){
		// 					flag = temp[k].access;
		// 					br = flag == true ? true : false;
		// 				}
		// 			}
		// 		} else {
		// 			flag = menus[index].children[ind].access;
		// 			br = flag == true ? true : false;
		// 		}
		// 	}

		// 	return flag;
		// },

		// checkC2: function(index, ind, menus){
		// 	var flag = false;
		// 	var br = false;

		// 	var temp = menus[index].children[ind].permissions;

		// 	if(br == false){
		// 		flag = menus[index].children[ind].access;
		// 		br = flag == true ? true : false;

		// 		if(temp.length > 0 && br == false){
		// 			for(var k = 0; k < temp.length; k++){
		// 				if(br == false){
		// 					flag = temp[k].access;
		// 					br = flag == true ? true : false;
		// 				}
		// 			}
		// 		} else {
		// 			flag = menus[index].children[ind].access;
		// 			br = flag == true ? true : false;
		// 		}
		// 	}

		// 	return flag;
		// },
		
		// checkC3: function(index, ind, menus){
		// 	var flag = false;
		// 	var br = false;

		// 	var temp = menus[index].children[ind].children;

		// 	if(br == false){
		// 		flag = menus[index].children[ind].access;
		// 		br = flag == true ? true : false;

		// 		if(temp.length > 0 && br == false){
		// 			for(var k = 0; k < temp.length; k++){
		// 				if(br == false){
		// 					flag = temp[k].access;
		// 					br = flag == true ? true : false;

		// 					if(temp.permissions && temp.permissions.length > 0 && br == false){
		// 						for(var l = 0; l < temp.permissions.length; l++){
		// 							if(br == false){
		// 								flag = temp[k].permissions[l].access;
		// 								br = flag == true ? true : false;
		// 							}
		// 						}
		// 					} else {
		// 						flag = menus[index].children[ind].children[k].access;
		// 						br = flag == true ? true : false;
		// 					}
		// 				}
		// 			}
		// 		}
		// 	}

		// 	return flag;
		// },

		// checkC4: function(index, ind, c_ind, menus){
		// 	var flag = false;
		// 	var br = false;

		// 	var temp = menus[index].children[ind].children[c_ind].permissions;

		// 	if(br == false){
		// 		flag = menus[index].children[ind].children[c_ind].access;
		// 		br = flag == true ? true : false;

		// 		if(temp.length > 0 && br == false){
		// 			for(var k = 0; k < temp.length; k++){
		// 				if(br == false){
		// 					flag = temp[k].access;
		// 					br = flag == true ? true : false;
		// 				}
		// 			}
		// 		} else {
		// 			flag = menus[index].children[ind].children[c_ind].access;
		// 			br = flag == true ? true : false;
		// 		}
		// 	}

		// 	return flag;
		// },

		// sanitizeMenus: function(menus) {
		// 	var temp_menus = menus;

		// 	for(var i = 0; i < menus.length; i++){
		// 		// level 1
		// 		if(menus[i].level == 1){
		// 			menus[i].access = this.checkP1(i, temp_menus);

		// 			for(var a = 0; a < menus[i].permissions.length; a++){
		// 				delete menus[i].permissions[a].assign;
		// 			}
		// 		}

		// 		// level 2
		// 		if(menus[i].level == 2){
		// 			menus[i].access = this.checkP2(i, temp_menus);
		// 			if(menus[i].children && menus[i].children.length > 0){
		// 				for(var j = 0; j < menus[i].children.length; j++){
		// 					menus[i].children[j].access = this.checkC1(i, j, temp_menus);
		// 					for(var b = 0; b < menus[i].children[j].permissions.length; b++){
		// 						delete menus[i].children[j].permissions[b].assign;
		// 					}
		// 					delete menus[i].children[j].assign;
		// 				}
		// 			}
		// 		}

		// 		// level 3
		// 		if(menus[i].level == 3){
		// 			menus[i].access = this.checkP3(i, temp_menus);
		// 			for(var k = 0; k < menus[i].children.length; k++){

		// 				if(menus[i].children[k].hasOwnProperty( 'permissions' )){
		// 					if(menus[i].children[k].permissions && menus[i].children[k].permissions.length > 0){
		// 						for(var l = 0; l < menus[i].children[k].permissions.length; l++){
		// 							menus[i].children[k].permissions[l].access = this.checkC1(i, k, temp_menus);

		// 							delete menus[i].children[k].permissions[l].assign;
		// 						}
		// 					}
		// 				}
		// 				if(menus[i].children[k].hasOwnProperty( 'children' )){

		// 					for(var m = 0; m < menus[i].children[k].children.length; m++){
		// 						menus[i].children[k].children[m].access = this.checkC3(i, k, temp_menus);

		// 						for(var n = 0; n < menus[i].children[k].children[m].permissions.length; n++){
		// 							menus[i].children[k].children[m].permissions[n].access = this.checkC4(i, k, m, temp_menus);

		// 							delete menus[i].children[k].children[m].permissions[n].assign;
		// 						}
		// 						delete menus[i].children[k].children[m].assign;
		// 					}
							
		// 				}

		// 				delete menus[i].children[k].assign;
		// 			}
		// 		}
		// 		delete menus[i].assign;
				
		// 		// // level 2
		// 		// if(menus[i].level == 2){
		// 		// 	if(menus[i].children.length > 0){
		// 		// 		for(var k = 0; k < menus[i].children.length; k++){
		// 		// 			var flagL2 = false;
		// 		// 			if(menus[i].children[k].permissions.length > 0){
		// 		// 				for(var l = 0; l < menus[i].children[k].permissions.length; l++){
		// 		// 					flagL2 = menus[i].children[k].permissions[l].access ? true : false;
		// 		// 					flag_main = menus[i].children[k].permissions[l].access ? true : false;
		// 		// 				}
		// 		// 			} else {
		// 		// 				flagL2 = menus[i].children[k].access ? true : false;
		// 		// 				flag_main = menus[i].children[k].access ? true : false;
		// 		// 			}
		// 		// 			menus[i].children[k].access = flagL2;
		// 		// 			flag_main = menus[i].children[k].access ? true : false;
		// 		// 		}
		// 		// 	}
		// 		// }

		// 		// // level 3
		// 		// if(menus[i].level == 3){
		// 		// 	if(menus[i].children.length > 0){
		// 		// 		for(var m = 0; m < menus[i].children.length; m++){
		// 		// 			var flagL22 = false;
							
		// 		// 			if(menus[i].children[m].hasOwnProperty( 'permissions' ) && menus[i].children[m].permissions.length > 0){
		// 		// 				for(var n = 0; n < menus[i].children[m].permissions.length; n++){
		// 		// 					flagL22 = menus[i].children[m].permissions[n].access ? true : false;
		// 		// 					flag_main = menus[i].children[m].permissions[n].access ? true : false;
		// 		// 				}
		// 		// 			} else {
		// 		// 				flagL22 = menus[i].children[m].access ? true : false;
		// 		// 				flag_main = menus[i].children[m].access ? true : false;
		// 		// 			}
							
		// 		// 			if(menus[i].children[m].hasOwnProperty( 'children' ) && menus[i].children[m].children.length > 0){
		// 		// 				for(var p = 0; p < menus[i].children[m].children.length; p++){
		// 		// 					var flag_L23 = false;
		// 		// 					if(menus[i].children[m].children[p].permissions.length > 0){
		// 		// 						for(var q = 0; q < menus[i].children[m].children[p].permissions.length; q++){
		// 		// 							flag_L23 = menus[i].children[m].children[p].permissions[q].access ? true : false;
		// 		// 							flag_main = menus[i].children[m].children[p].permissions[q].access ? true : false;
		// 		// 						}
		// 		// 					} else {
		// 		// 						flag_L23 = menus[i].children[m].children[p].access ? true : false;
		// 		// 						flag_main = menus[i].children[m].children[p].access ? true : false;
		// 		// 					}
		// 		// 					menus[i].children[m].children[p].access = flag_L23;
		// 		// 					flagL22 = menus[i].children[m].children[p].access ? true : false;
		// 		// 					flag_main = menus[i].children[m].children[p].access ? true : false;
		// 		// 				}
		// 		// 			}

		// 		// 			menus[i].children[m].access = flagL22;
		// 		// 			flag_main = menus[i].children[m].access ? true : false;
		// 		// 		}
		// 		// 	}
		// 		// }

		// 		// menus[i].access = flag_main;
		// 	}
		// 	return menus;
		// },

		checkPermission: function (route, label) {
			var flag = false;
			if(store.getters.currentUser){
				store.getters.currentUser.menu_permission.forEach(menu => {
				// for menu with parent level 1
					if(menu.level == 1){
						if(menu.route == route){
							menu.permissions.forEach(access => {
								if(access.label == label){
									flag = access.access;
								}
							});
						}
					}
				// for menu with children level 2
					if(menu.level == 2){
						menu.children.forEach(child => {
							if(child.route == route){
								child.permissions.forEach(access => {
									if(access.label == label){
										flag = access.access;
									}
								});
							}
						});
					}
				// for level 3
					if(menu.level == 3){
						menu.children.forEach(child => {

							if(child.permissions){
								if(child.route == route){
									child.permissions.forEach(access => {
										if(access.label == label){
											flag = access.access;
										}
									});
								}
							}

							if(child.children){
								child.children.forEach(child2 => {
									if(child2.access == true){
										if(child2.route == route){
											child2.permissions.forEach(access2 => {
												if(access2.label == label){
													flag = access2.access;
												}
											});
										}
									}
								});
							}

						});
					}

				});
			}
			return flag;
			// return true;
		},

		showHideMenu: function (title, type) {
			var flag = false;
			if(store.getters.currentUser){
				store.getters.currentUser.menu_permission.forEach(menu => {
				// for menu with parent
					if(menu.title == title && type == 'parent'){
						flag = menu.access;
					}
				// for menu with children
					else if (menu.children && type == 'child'){
						menu.children.forEach(child => {
							if(child.title == title){
								flag = child.access;
							}
						});
					}
				});
			}
			return flag;
			// return true;
		},

		showHideMenuPermission: function (title, label) {
			var flag = false;
			if(store.getters.currentUser){
				store.getters.currentUser.menu_permission.forEach(menu => {
				// for menu with parent
					if(menu.permissions){
						if(menu.title == title){
							menu.permissions.forEach(access => {
								if(access.label == label){
									flag = access.access;
								}
							});
						}
					}
				// for menu with children
					if(menu.children){
						menu.children.forEach(child => {
							if(child.title == title){
								if(child.permissions){
									child.permissions.forEach(access => {
										if(access.label == label){
											flag = access.access;
										}
									});
								}
							}
						});
					}
				});
			}
			return flag;
		},

		checkMenus: function (menus) {
			var flag = false;
			menus.forEach(menu => {
				if (menu.access === true) {
					flag = true;
					return flag;
				}
			});
			return flag;
		},

		// showHideMenuPermissionL3Sub: function (title, label) {
		// 	var flag = false;
		// 	if(store.getters.currentUser){
		// 		store.getters.currentUser.menu_permission.forEach(menu => {
		// 			if(menu.level == 3){
		// 				if(menu.children){
		// 					menu.children.forEach(child => {
		// 						if(child.title == title){
		// 							if(child.permissions){
		// 								child.permissions.forEach(access => {
		// 									if(access.label == label){
		// 										flag = access.access;
		// 									}
		// 								});										
		// 							}
		// 						}
		// 					});
		// 				}
		// 			}
		// 		});
		// 	}
		// 	return flag;
		// },
		
		showHideMenuPermissionL3: function (title, L2, L3) {
			var flag = false;
			if(store.getters.currentUser){
				store.getters.currentUser.menu_permission.forEach(menu => {
				// Level 3 match
					if(menu.level == 3 && menu.title == title){
						menu.children.forEach(child => {
							if(child.title == L2){
								if(child.permissions){
									child.permissions.forEach(access => {
										if(access.label == L2){
											flag = access.access;
										} 
									});
								} else {
									child.children.forEach(sub => {
										if(sub.title == L3){
											flag = sub.access;
										}
									})
								}
							}
						});
					}
				});
			}
			return flag;
		},

		setDefaultPage: function (route) {
			var flag = false;
			var route = null;
			store.getters.currentUser.menu_permission.forEach(menu => {
				if(!flag){
					if (menu.access) {
						if(menu.hasOwnProperty('permissions')){
							route = menu.route
							flag = true
						}
						if(menu.hasOwnProperty('children')){
							menu.children.forEach(child => {
								if(!flag){
									route = child.route
									flag = true
								}
							});
						}
					}
				}	
			});
			return route;
		},

		successAlert: function(){
			return Swal.fire({
				position : 'center',
				icon     : 'success',
				title    : this.$store.getters.getResults.message,
				timer    : 1500,
				showConfirmButton : false
			});
		},
		
		errorAlert: function(){
			return Swal.fire({
				position : 'center',
				icon     : 'error',
				title    : this.$store.getters.getErrors,
				timer    : 1500,
				showConfirmButton : false
			});
		},

		errorAlertCF: function(){
			return Swal.fire({
				position : 'center',
				icon     : 'error',
				title    : this.$store.getters.getErrors
			});
		},

		decisionAlert: function(msg){
			return Swal.fire({
				title              : 'Please Confirm',
				text               : msg,
				icon               : 'warning',
				position           : 'center',
				showCancelButton   : true,
				confirmButtonColor : '#3085d6',
				cancelButtonColor  : '#d33',
				confirmButtonText  : 'Yes',
				cancelButtonText   : 'No'
			});
		},
		
		decisionAlertBackdrop: function(msg){
			return Swal.fire({
				title              : 'Please Confirm',
				text               : msg,
				icon               : 'warning',
				position           : 'center',
				showCancelButton   : true,
				confirmButtonColor : '#3085d6',
				cancelButtonColor  : '#d33',
				confirmButtonText  : 'Yes',
				cancelButtonText   : 'No',
				allowOutsideClick  : false
			});
		},
		
		customAlert: function(title, msg, variant){
			return Swal.fire({
				title              : title,
				text               : msg,
				icon               : variant,
				position           : 'center',
			});
		},

		customAlert2: function(title, msg, variant, position, showConfirmButton){
			return Swal.fire({
				position           : 'center',
				title              : title,
				text               : msg,
				icon               : variant,
				showConfirmButton  : showConfirmButton
			});
		},

		breadCrumList:function(route_name,label){
			var menus = this.$store.getters.currentUser.menu_permission;
			var b_array = [];

			for (var i = 0; i < menus.length; i++) {
				if(typeof menus[i].route != 'undefined' && menus[i].route == route_name){

					b_array.push({
			          text: menus[i].title,
			          to:menus[i].route
			        });

			        return b_array;

				}else if (typeof menus[i].route == 'undefined' && menus[i].children.length > 0){
					
					for (var j = 0; j < menus[i].children.length; j++) {
						
						if(menus[i].children[j].route == route_name) {
							
							b_array.push({
					          text: menus[i].title,
					          to:null
					        });

					        b_array.push({
					          text: menus[i].children[j].title,
					          to:menus[i].children[j].route
					        });
					        
					        return b_array;
			        	
						}
					}
				}
			}
		},
		sitedateTime:function(value,date_format,time_format){
			
			if (!value) return 'N/A'
		
			value = moment(value).tz('Asia/Singapore').format(date_format+' '+time_format);
			return value
		},
		sitetime:function(value,time_format){
			if (!value) return ''
			//value = new Date('2022-01-12 ' + value)
			value = moment(value,'HH:mm').format(time_format);
			return value
	
		},
		sitedateRangePicker: function(value,date_format,time_format){
			if (!value) return ''

			value = moment(value).format(date_format+' '+time_format)
			return value
		},
		sitedateTime2:function(value,date_format,time_format){
			
			if (!value) return 'N/A'
		
			value = moment(value).format(date_format+' '+time_format)
			return value
	
		},
		sitetimeHM:function(value,time_format){
			if (!value) return ''
			//value = new Date('2022-01-12 ' + value)
			value = moment(value).format(time_format);
			return value
		},
		filterReportBreadCrum: function(item){

			if(this.checkPermission('custom-forms-dashboard', 'Dashboard')){

				return item;
			}else{
				const indexOfObject = item.findIndex(object => {
				  return object.text === 'Form Dashboard';
				});
				item.splice(indexOfObject, 1);
				return item;
			}

		},
		
		// to set default site based on user role
		defaultSite: function(sites) {
			var admin = ['administrator', 'admin', 'director', 'senior_operation_manager', 'operation_manager','operation_executive', 'site_client', 'site_manager'];
			var sup = ['supervisor', 'crew'];
			var site = '';

			if(admin.includes(this.$store.getters.currentUser.role)) {
				site = sites.find(ad => ad._id == 'all-site');
				if(site == undefined){
					site = sites[0]
				}
			} else if (sup.includes(this.$store.getters.currentUser.role)) {
				var main_site = this.$store.getters.currentUser.supervisor_sites.find(el => el.main == 'yes');
				site = sites.find(st => st._id == main_site.site);
			} else {
				site = '';
			}
			return site;
		},

		// to set default site based on user role
		defaultSiteWithAllSite: function(sites) {
			var admin = ['administrator', 'admin', 'director', 'senior_operation_manager', 'operation_manager','operation_executive', 'site_client', 'site_manager'];
			var sup = ['supervisor', 'crew'];
			var site = '';

			if(admin.includes(this.$store.getters.currentUser.role)) {
				site = sites && sites.length > 0 ? sites[1] : '';
				/* site = sites.find(ad => ad._id == 'all-site');
				if(site != undefined){
					site = sites[0]
				} */
			} else if (sup.includes(this.$store.getters.currentUser.role)) {
				var main_site = this.$store.getters.currentUser.supervisor_sites.find(el => el.main == 'yes');
				site = sites.find(st => st._id == main_site.site);
			} else {
				site = '';
			}
			return site;
		},

		getPdfFileName: function(site, file_name, start, end) {
			var date = '';
			if(start == end){
				date = moment(start, 'YYYY-MM-DD').format('DD-MMM-YYYY');
			} else {
				date = moment(start, 'YYYY-MM-DD').format('DD-MMM-YYYY') + '_' + moment(end, 'YYYY-MM-DD').format('DD-MMM-YYYY');
			}
			return site + '_' + file_name + '_' + date;
		}
	}
})


new Vue({
  router,
  store,
  /*i18n,*/
  render: h => h(App),
  created() {
	window.addEventListener('beforeunload', () => {
	  	localStorage.setItem("routeCFCheck", "no")
	}, false)
  },
}).$mount('#app')
