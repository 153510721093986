const ID_API_TOKEN = 'id_api_token';
const LOGGED_IN_PANEL = 'logged_in_panel';
const LOGGED_IN_COMP_EMAIL = 'logged_in_comp_email';
const LOGGED_IN_ADMIN_EMAIL = 'logged_in_admin_email';
const LOGGED_IN_COMP_PASS = 'logged_in_comp_pass';
const COMP_REMEMBER_ME = 'comp_remember_me';
const ADMIN_REMEMBER_ME = 'admin_remember_me';
const LOGGED_IN_ADMIN_PASS = 'logged_in_admin_pass';
const USER_ID = 'user_id';
const USER_TYPE = 'user_type';
const JOB_ID = 'job_id';
const PT_USER_ID = 'ptuser_id';
const MENU_PERMISSION = 'menu_permission';




export const getToken = () => {
    return window.localStorage.getItem(ID_API_TOKEN)
};

export const getUserId = () => {
    return window.localStorage.getItem(USER_ID)
};

export const getUserType = () => {
    return window.localStorage.getItem(USER_TYPE)
};

export const getJobId = () => {
    return window.localStorage.getItem(ID_API_TOKEN)
};

export const getPtUserId = () => {
    return window.localStorage.getItem(ID_API_TOKEN)
};

export const getTLoggedInPanel = () => {
    return window.localStorage.getItem(LOGGED_IN_PANEL)
};

export const getCompanyEmail = () => {
    return window.localStorage.getItem(LOGGED_IN_COMP_EMAIL)
}

export const getAdminEmail = () => {
    return window.localStorage.getItem(LOGGED_IN_ADMIN_EMAIL)
}

export const getCompanyPass = () => {
    return window.localStorage.getItem(LOGGED_IN_COMP_PASS)
}

export const getAdminPass = () => {
    return window.localStorage.getItem(LOGGED_IN_ADMIN_PASS)
}

export const getCompanyRememberMe = () => {
    return window.localStorage.getItem(COMP_REMEMBER_ME)
}

export const getAdminRememberMe = () => {
    return window.localStorage.getItem(ADMIN_REMEMBER_ME)
}

export const getMenuPermission = () => {
    return window.localStorage.getItem(MENU_PERMISSION)
}

export const setCompanyEmail = token => {
    window.localStorage.setItem(LOGGED_IN_COMP_EMAIL, token)
}

export const setAdminEmail = token => {
    window.localStorage.setItem(LOGGED_IN_ADMIN_EMAIL, token)
}

export const setCompanyPass = token => {
    window.localStorage.setItem(LOGGED_IN_COMP_PASS, token)
}

export const setAdminPass = token => {
    window.localStorage.setItem(LOGGED_IN_ADMIN_PASS, token)
}

export const setCompanyRememberMe = token => {
    window.localStorage.setItem(COMP_REMEMBER_ME, token)
}

export const setAdminRememberMe = token => {
    window.localStorage.setItem(ADMIN_REMEMBER_ME, token)
}

export const setToken = token => {
    window.localStorage.setItem(ID_API_TOKEN, token, token);
};

export const setTLoggedInPanel = panel => {
    window.localStorage.setItem(LOGGED_IN_PANEL, panel);
};

export const setUserId = panel => {
    window.localStorage.setItem(USER_ID, panel);
};

export const setUserType = panel => {
    window.localStorage.setItem(USER_TYPE, panel);
};

export const setJobId = panel => {
    window.localStorage.setItem(JOB_ID, panel);
};

export const setPtUserId = panel => {
    window.localStorage.setItem(PT_USER_ID, panel);
};

export const setMenuPermission = panel => {
    window.localStorage.setItem(MENU_PERMISSION, panel);
};



export const unsetToken = () => {
    window.localStorage.removeItem(ID_API_TOKEN);
    window.localStorage.removeItem(LOGGED_IN_PANEL);
    window.localStorage.removeItem(MENU_PERMISSION);
};


export default { getToken, getTLoggedInPanel, setToken, setTLoggedInPanel, unsetToken, getCompanyEmail, getAdminEmail, getCompanyPass, getAdminPass, setCompanyEmail, setAdminEmail, setCompanyPass, setAdminPass, setAdminRememberMe, setCompanyRememberMe, getCompanyRememberMe, getAdminRememberMe,setUserId,setUserType,getUserId,getUserType,setPtUserId,setJobId,getPtUserId,getJobId,setMenuPermission, getMenuPermission }
