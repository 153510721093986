import ApiService from "../../api/api.service";
import JwtService from "../../common/jwt.service";

import { SET_ERROR, CLEAR_ERRORS, SET_RESULT_DATA, CLEAR_RESULT_DATA } from "../mutations.type";
import {  GET_API, POST_API, PUT_API, DELETE_API, UPDATE_AUTH_POST_API , UPDATE_AUTH_PUT_API, SET_PROFILE_DATA,LOGOUT, MULTIPART_POST_API, POST_API_SUCCESS, POST_API_N } from "../actions.type";
import Bus from "../../event-bus";

export default {
    state: {

    },
    getters: {
    },
        
    actions: {

    	[GET_API](context, credentials) {
            // console.log(credentials);
            return new Promise((resolve, reject) => {
                ApiService.setHeader();
                ApiService.get(credentials.api)
                 .then(({ data }) => {
                            context.commit(CLEAR_ERRORS);
                            if (!Boolean(data.success)) {
                                /*context.commit(
                                    SET_ERROR, { message: data.message }
                                );
                                reject(data);*/
                                if (typeof data.invalid == 'undefined') {
                                    
                                    context.commit(
                                        SET_ERROR, { message: data.message }
                                    );
                                    reject(data);  
                                }
                            } else {
                                context.commit(
                                    SET_RESULT_DATA, { resultData: data }
                                );
                                resolve(data);
                            }
                        })
                        .catch(({ response }) => {
                            console.log("Something went wrong1");
                        });
                })
                .catch(response => {
                    if(response.logout == true)
                    {
                        console.log('logot');
                        if(JwtService.getTLoggedInPanel() == 'company')
                        {
                            context.commit(LOGOUT);
                            window.location.replace('/login');
                        }
                        else
                        {
                            context.commit(LOGOUT);
                            window.location.replace('/admin-login');
                        }
                    }
                    console.log("Something went wrong2");
                });
        },
        [POST_API](context, credentials) {
            
            
            return new Promise((resolve, reject) => {
                    ApiService.setHeader();
                    Bus.$emit('showLoader');
                    ApiService.post(credentials.api, credentials.data)
                        .then(({ data }) => {
                            
                            context.commit(CLEAR_ERRORS);
                            if (!Boolean(data.success)) {
                                Bus.$emit('hideLoader');
                                if (typeof data.invalid == 'undefined') {
                                    context.commit(
                                        SET_ERROR, { message: data.message }
                                    );
                                    reject(data);  
                                }else{
                                    context.commit(LOGOUT);
                                    window.location.replace('/not-found');
                                }
                                

                            } else {
                                // console.log('b');
                                Bus.$emit('hideLoader');
                                context.commit(
                                    SET_RESULT_DATA, { resultData: data }
                                );
                                resolve(data);
                            }
                        })
                        .catch(({ response }) => {
                            Bus.$emit('hideLoader');
                            
                            console.log(response);
                            console.log('a');
                            if(typeof response != 'undefined' && response.data.logout != 'undefined' && response.data.logout == true){ 
                                var user_role = JSON.parse(localStorage.getItem('userData'));;
                               
                               if (user_role.role == 'super_admin'){
                                    //console.log('admin logout');
                                    context.commit(LOGOUT);
                                    Bus.$emit('adminLogout');
                                    //window.location.replace('/login');
                               }else if(user_role.role != 'super_admin'){
                                    context.commit(LOGOUT);
                                    Bus.$emit('clientLogout');
                               }
                               // Bus.$emit('logout');

                            }
                            console.log("Something went wrong1");
                        });
                })
                .catch(response => {
                    console.log("Something went wrong2");
                });
        },
        
        [POST_API_SUCCESS](context, credentials) {
            
            
            return new Promise((resolve, reject) => {
                    ApiService.setHeader();
                    Bus.$emit('showLoaderSuccess');
                    ApiService.post(credentials.api, credentials.data)
                        .then(({ data }) => {
                            
                            context.commit(CLEAR_ERRORS);
                            if (!Boolean(data.success)) {
                                Bus.$emit('hideLoader');
                                if (typeof data.invalid == 'undefined') {
                                    context.commit(
                                        SET_ERROR, { message: data.message }
                                    );
                                    reject(data);  
                                }else{
                                    context.commit(LOGOUT);
                                    window.location.replace('/not-found');
                                }
                                

                            } else {
                                Bus.$emit('hideLoaderSuccess');
                                context.commit(
                                    SET_RESULT_DATA, { resultData: data }
                                );
                                resolve(data);
                            }
                        })
                        .catch(({ response }) => {
                            Bus.$emit('hideLoaderSuccess');
                            
                            console.log(response);

                            if(typeof response != 'undefined' && response.data.logout != 'undefined' && response.data.logout == true){ 
                                var user_role = JSON.parse(localStorage.getItem('userData'));;
                               
                               if (user_role.role == 'super_admin'){
                                    //console.log('admin logout');
                                    context.commit(LOGOUT);
                                    Bus.$emit('adminLogout');
                                    //window.location.replace('/login');
                               }else if(user_role.role != 'super_admin'){
                                    context.commit(LOGOUT);
                                    Bus.$emit('clientLogout');
                               }
                               // Bus.$emit('logout');

                            }
                            console.log("Something went wrong1");
                        });
                })
                .catch(response => {
                    console.log("Something went wrong2");
                });
        },
        
        [PUT_API](context, credentials) {
            return new Promise((resolve, reject) => {
                    ApiService.setHeader();
                    ApiService.put(credentials.api+credentials.data.id, credentials.data)
                        .then(({ data }) => {
                            context.commit(CLEAR_ERRORS);
                            if (!Boolean(data.success)) {
                                
                                
                                context.commit(
                                    SET_ERROR, { message: data.message }
                                );
                                reject(data);
                                
                                
                            } else {
                                
                                context.commit(
                                    SET_RESULT_DATA, { resultData: data.data }
                                );
                                resolve(data);
                            }
                        })
                        .catch(({ response }) => {
                            console.log("Something went wrong");
                        });
                })
                .catch(response => {
                    if(response.logout == true)
                    {
                        if(JwtService.getTLoggedInPanel() == 'company')
                        {
                            context.commit(LOGOUT);
                            window.location.replace('/login');
                        }
                        else
                        {
                            context.commit(LOGOUT);
                            window.location.replace('/admin-login');
                        }
                    }
                    console.log("Something went wrong");
                });

        },
        [DELETE_API](context, credentials) {
            // console.log(credentials.data)
            return new Promise((resolve, reject) => {
                    ApiService.setHeader();
                    ApiService.delete(credentials.api+credentials.data.id, credentials.data)
                        .then(({ data }) => {
                            context.commit(CLEAR_ERRORS);
                            if (!Boolean(data.success)) {
                                context.commit(
                                    SET_ERROR, { message: data.message }
                                );
                                reject(data);
                            } else {
                                context.commit(
                                    SET_RESULT_DATA, { resultData: data }
                                );
                                resolve(data);
                            }
                        })
                        .catch(({ response }) => {
                            console.log("Something went wrong");
                        });
                })
                .catch(response => {
                    if(response.logout == true)
                    {
                        if(JwtService.getTLoggedInPanel() == 'company')
                        {
                            context.commit(LOGOUT);
                            window.location.replace('/login');
                        }
                        else
                        {
                            context.commit(LOGOUT);
                            window.location.replace('/admin-login');
                        }
                    }
                    console.log("Something went wrong");
                });
        },
        [UPDATE_AUTH_PUT_API](context, credentials) {
            // console.log(credentials.data)
            return new Promise((resolve, reject) => {
                ApiService.setMultipartHeader();
                    ApiService.setHeader();
                    ApiService.put(credentials.api+credentials.data.id, credentials.data)
                        .then(({ data }) => {
                            context.commit(CLEAR_ERRORS);
                            if (!Boolean(data.success)) {
                                context.commit(
                                    SET_ERROR, { message: data.message }
                                );
                                reject(data);
                            } else {
                                context.commit(
                                    SET_PROFILE_DATA, { resultData: data }
                                );
                                context.commit(
                                    SET_RESULT_DATA, { resultData: data }
                                );
                                resolve(data);
                            }
                        })
                        .catch(({ response }) => {
                            console.log("Something went wrong");
                        });
                })
                .catch(response => {
                    if(response.logout == true)
                    {
                        if(JwtService.getTLoggedInPanel() == 'company')
                        {
                            context.commit(LOGOUT);
                            window.location.replace('/login');
                        }
                        else
                        {
                            context.commit(LOGOUT);
                            window.location.replace('/admin-login');
                        }
                    }
                    console.log("Something went wrong");
                });
        },
        
        [UPDATE_AUTH_POST_API](context, credentials) {
            // console.log(credentials.data)
            return new Promise((resolve, reject) => {
                ApiService.setMultipartHeader();
                    // ApiService.setHeader();
                    ApiService.post(credentials.api, credentials.data)
                        .then(({ data }) => {
                            context.commit(CLEAR_ERRORS);
                            if (!Boolean(data.success)) {
                                
                                context.commit(
                                    SET_ERROR, { message: data.message }
                                );
                                reject(data);

                            } else {
                                context.commit(
                                    SET_PROFILE_DATA, { resultData: data }
                                );
                                context.commit(
                                    SET_RESULT_DATA, { resultData: data }
                                );
                                resolve(data);
                            }
                        })
                        .catch(({ response }) => {
                            if(response.logout == true)
                            {
                                if(JwtService.getTLoggedInPanel() == 'company')
                                {
                                    context.commit(LOGOUT);
                                    window.location.replace('/login');
                                }
                                else
                                {
                                    context.commit(LOGOUT);
                                    window.location.replace('/admin-login');
                                }
                            }
                            console.log("Something went wrong");
                        });
                })
                .catch(response => {
                    if(response.logout == true)
                    {
                        context.commit(LOGOUT);
                    }
                    console.log("Something went wrong");
                });
        },
        [MULTIPART_POST_API](context, credentials) {
            return new Promise((resolve, reject) => {
                ApiService.setMultipartHeader();
                    ApiService.post(credentials.api, credentials.data)
                        .then(({ data }) => {
                            context.commit(CLEAR_ERRORS);
                            if (!Boolean(data.success)) {
                                context.commit(
                                    SET_ERROR, { message: data.message }
                                );
                                reject(data);
                            } else {
                                context.commit(
                                    SET_RESULT_DATA, { resultData: data }
                                );
                                resolve(data);
                            }
                        })
                        .catch(({ response }) => {
                            if(response.logout == true)
                            {
                                if(JwtService.getTLoggedInPanel() == 'company')
                                {
                                    context.commit(LOGOUT);
                                    window.location.replace('/login');
                                }
                                else
                                {
                                    context.commit(LOGOUT);
                                    window.location.replace('/admin-login');
                                }
                            }
                            console.log("Something went wrong");
                        });
                })
                .catch(response => {
                    if(response.logout == true)
                    {
                        context.commit(LOGOUT);
                    }
                    console.log("Something went wrong");
                });
        },
        
    }
}

