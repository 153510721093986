export default [
  {
    path: '/client/dashboard',
    name: 'client-dashboard',
    route: 'client-dashboard',
    component: () => import('@/views/client/dashboard'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' }
  },
  {
    path: '/client/job-sites',
    name: 'job-sites',
    route: 'job-sites',
    component: () => import('@/views/client/job_sites/SiteList'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved'}
  },
  {
    path: '/client/archive-job-sites',
    name: 'archive-job-sites',
    route: 'archive-job-sites',
    component: () => import('@/views/client/job_sites/ArchiveSite'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved'}
  },
  {
    path: '/client/add-job-sites',
    name: 'add-job-sites',
    route: 'add-job-sites',
    component: () => import('@/views/client/job_sites/AddSite'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'job-sites'}
  },
  {
    path: '/client/edit-job-sites/:id',
    name: 'edit-job-sites',
    route: 'edit-job-sites',
    component: () => import('@/views/client/job_sites/EditSite'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'job-sites'}
  },
  {
    path: '/client/site-locations/:site_id',
    name: 'site-locations',
    route: 'site-locations',
    component: () => import('@/views/client/job_site_locations/LocationList'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'job-sites'}
  },
  {
    path: '/client/add-site-locations/:site_id',
    name: 'add-site-locations',
    route: 'add-site-locations',
    component: () => import('@/views/client/job_site_locations/AddLocation'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'job-sites'}
  },
  {
    path: '/client/edit-site-locations/:id',
    name: 'edit-site-locations',
    route: 'edit-site-locations',
    component: () => import('@/views/client/job_site_locations/EditLocation'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'job-sites'}
  },
  {
    path: '/client/location-areas/:site_id/:location_id',
    name: 'location-areas',
    route: 'location-areas',
    component: () => import('@/views/client/location_area/AreaList'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'job-sites'}
  },
  {
    path: '/client/feedback-panel-settings/:site_id?/:location_id?',
    name: 'feedback-panel-settings',
    route: 'feedback-panel-settings',
    component: () => import('@/views/client/feedback_panel_settings/Index'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: ''}
  },
  {
    path: '/client/settings-panel/:site_id?/:location_id?',
    name: 'settings-panel',
    route: 'settings-panel',
    component: () => import('@/views/client/feedback_panel_settings/Panel'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' ,
    navActiveLink: ''}
  },
  {
    path: '/client/qr-code-settings/:site_id?/:location_id?',
    name: 'qr-code-settings',
    route: 'qr-code-settings',
    component: () => import('@/views/client/feedback_panel_settings/Qr_code_settings'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'job-sites'}
  },
  {
    path: '/client/label-settings/:site_id?/:location_id?',
    name: 'label-settings',
    route: 'label-settings',
    component: () => import('@/views/client/feedback_panel_settings/Label_settings'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'job-sites'}
  },
  {
    path: '/client/header-settings/:site_id?/:location_id?',
    name: 'header-settings',
    route: 'header-settings',
    component: () => import('@/views/client/feedback_panel_settings/Header_settings'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'job-sites'}
  },
  {
    path: '/client/footer-settings/:site_id?/:location_id?',
    name: 'footer-settings',
    route: 'footer-settings',
    component: () => import('@/views/client/feedback_panel_settings/Footer_settings'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'job-sites'}
  },
  {
    path: '/client/service-time-settings/:site_id?/:location_id?',
    name: 'service-time-settings',
    route: 'service-time-settings',
    component: () => import('@/views/client/feedback_panel_settings/Service_time_settings'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'job-sites'}
  },
  {
    path: '/client/change-background/:site_id?/:location_id?',
    name: 'change-background',
    route: 'change-background',
    component: () => import('@/views/client/feedback_panel_settings/Change_background'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'job-sites'}
  },
  {
    path: '/client/change-pincode/:site_id?/:location_id?',
    name: 'change-pincode',
    route: 'change-pincode',
    component: () => import('@/views/client/feedback_panel_settings/Change_pincode'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'job-sites'}
  },
  {
    path: '/client/add-area/:site_id/:location_id',
    name: 'add-area',
    route: 'add-area',
    component: () => import('@/views/client/location_area/AddArea'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'job-sites'}
  },
  {
    path: '/client/edit-area/:id',
    name: 'edit-area',
    route: 'edit-area',
    component: () => import('@/views/client/location_area/EditArea'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'job-sites'}
  },
  {
    path: '/client/email-users',
    name: 'email-users',
    route: 'email-users',
    component: () => import('@/views/client/email_user/EmailUser'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' }
  },
  {
    path: '/client/add-email-user',
    name: 'add-email-user',
    route: 'add-email-user',
    component: () => import('@/views/client/email_user/AddEmailUser'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'email-users'}
  },
  {
    path: '/client/edit-email-user/:id',
    name: 'edit-email-user',
    route: 'edit-email-user',
    component: () => import('@/views/client/email_user/EditEmailUser'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'email-users'}
  },
  {
    path: '/client/email-logs',
    name: 'email-logs',
    route: 'email-logs',
    component: () => import('@/views/client/email_user/EmailLogs'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' }
  },
  {
    path: '/client/client-users',
    name: 'client-users',
    route: 'client-users',
    component: () => import('@/views/client/users/UserList'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' }
  },
  {
    path: '/client/add-client-user',
    name: 'add-client-user',
    route: 'add-client-user',
    component: () => import('@/views/client/users/AddUser'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'client-users'}
  },
  {
    path: '/client/add-new-client-user',
    name: 'add-new-client-user',
    route: 'add-new-client-user',
    component: () => import('@/views/client/new_users/AddNewUser'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    }
  },
  {
    path: '/client/edit-client-user/:id',
    name: 'edit-client-user',
    route: 'edit-client-user',
    component: () => import('@/views/client/users/EditUser'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'client-users'}
  },{
    path: '/client/duplicate-client-user/:id',
    name: 'duplicate-client-user',
    route: 'duplicate-client-user',
    component: () => import('@/views/client/users/DuplicateUser'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'client-users'}
  },
  {
    path: '/client/view-client-user/:id',
    name: 'view-client-user',
    route: 'view-client-user',
    component: () => import('@/views/client/users/ViewUser'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'client-users'}
  },
  {
    path: '/client/shifts',
    name: 'shifts',
    route: 'shifts',
    component: () => import('@/views/client/shifts/ShiftList'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' }
  },
  {
    path: '/client/add-shift',
    name: 'add-shift',
    route: 'add-shift',
    component: () => import('@/views/client/shifts/AddShift'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'shifts'}
  },
  {
    path: '/client/edit-shift/:id',
    name: 'edit-shift',
    route: 'edit-shift',
    component: () => import('@/views/client/shifts/EditShift'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'shifts'}
  },
  /*{
    path: '/client/job-titles',
    name: 'job-title',
    route: 'job-title',
    component: () => import('@/views/client/job_title/JobTitleList'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' }
  },
  {
    path: '/client/add-job-title',
    name: 'add-job-title',
    route: 'add-job-title',
    component: () => import('@/views/client/job_title/AddJobTitle'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'job-title'}
  },
  {
    path: '/client/edit-job-title/:id',
    name: 'edit-job-title',
    route: 'edit-job-title',
    component: () => import('@/views/client/job_title/EditJobTitle'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'job-title'}
  },*/
  {
    path: '/client/trainings',
    name: 'trainings',
    route: 'trainings',
    component: () => import('@/views/client/trainings/TrainingList'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' }
  },
  {
    path: '/client/add-training',
    name: 'add-training',
    route: 'add-training',
    component: () => import('@/views/client/trainings/AddTraining'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'trainings'}
  },
  {
    path: '/client/edit-training/:id',
    name: 'edit-training',
    route: 'edit-training',
    component: () => import('@/views/client/trainings/EditTraining'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'trainings'}
  },
  {
    path: '/client/adhoc-tasks/:site?/:location?/:type?/:crew?/:start?/:end?/:tab_status?/:assign_status?',
    name: 'adhoc-tasks',
    route: 'adhoc-tasks',
    component: () => import('@/views/client/tasks/TaskList'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' }
  },
  {
    path: '/client/add-adhoc-task',
    name: 'add-adhoc-task',
    route: 'add-adhoc-task',
    component: () => import('@/views/client/tasks/AddAdhoc'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'adhoc-tasks'}
  },
  {
    path: '/client/edit-adhoc-task/:id',
    name: 'edit-adhoc-task',
    route: 'edit-adhoc-task',
    component: () => import('@/views/client/tasks/EditAdhoc'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'adhoc-tasks'}
  },
  {
    path: '/client/tasks-calendar',
    name: 'task-calendar',
    route: 'task-calendar',
    component: () => import('@/views/client/tasks/Calendar'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' }
  },
  {
    path: '/client/periodic-tasks',
    name: 'periodic-tasks',
    route: 'periodic-tasks',
    component: () => import('@/views/client/periodic_tasks/TaskList'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' }
  },
  {
    // path: '/client/periodic-sub-tasks/:site?/:frequency?/:page?/:start?/:end?/:status?/:group?/:assignedStatus?/:crew?',
    path: '/client/periodic-sub-tasks/:site?/:location?/:group?/:frequencyData?/:crew?/:start?/:end?/:tab_status?/:assign_status?',
    name: 'periodic-sub-tasks',
    route: 'periodic-sub-tasks',
    component: () => import('@/views/client/periodic_tasks/Tasks'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' }
  },
  {
    path: '/client/add-periodic-task',
    name: 'add-periodic-task',
    route: 'add-periodic-task',
    component: () => import('@/views/client/periodic_tasks/AddTask'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'periodic-tasks'}
  },
  {
    path: '/client/edit-periodic-task/:id',
    name: 'edit-periodic-task',
    route: 'edit-periodic-task',
    component: () => import('@/views/client/periodic_tasks/EditTask'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'periodic-tasks'}
  },
  {
    path: '/client/import-tasks',
    name: 'import-tasks',
    route: 'import-tasks',
    component: () => import('@/views/client/periodic_tasks/ImportTask'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved'}
  },
  {
    path: '/client/quality-inspection/forms',
    name: 'forms',
    route: 'forms',
    component: () => import('@/views/client/forms/FormList'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved', 
    navActiveLink: 'forms' }
  },
  {
    path: '/client/quality-inspection/add-form',
    name: 'add-form',
    route: 'add-form',
    component: () => import('@/views/client/forms/AddForm'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'forms'}
  },
  {
    path: '/client/quality-inspection/edit-form/:id',
    name: 'edit-form',
    route: 'edit-form',
    component: () => import('@/views/client/forms/EditForm'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'forms'}
  },
  {
    path: '/client/quality-inspection/units',
    name: 'units',
    route: 'units',
    component: () => import('@/views/client/units/UnitList'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved',
    navActiveLink: 'forms' }
  },
  {
    path: '/client/quality-inspection/add-unit',
    name: 'add-unit',
    route: 'add-unit',
    component: () => import('@/views/client/units/AddUnit'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'forms' }
  },
  {
    path: '/client/quality-inspection/edit-unit/:id',
    name: 'edit-unit',
    route: 'edit-unit',
    component: () => import('@/views/client/units/EditUnit'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'forms' }
  },
  {
    path: '/client/quality-inspection/elements',
    name: 'elements',
    route: 'elements',
    component: () => import('@/views/client/elements/ElementList'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved',
    navActiveLink: 'forms' }
  },
  {
    path: '/client/quality-inspection/add-element',
    name: 'add-element',
    route: 'add-element',
    component: () => import('@/views/client/elements/AddElement'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'forms'}
  },
  {
    path: '/client/quality-inspection/edit-element/:id',
    name: 'edit-element',
    route: 'edit-element',
    component: () => import('@/views/client/elements/EditElement'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'forms'}
  },
  {
    path: '/client/quality-inspection/import',
    name: 'importQi',
    route: 'importQi',
    component: () => import('@/views/client/inspection_units/Import'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' }
  },
  {
    path: '/client/quality-inspection/inspection_unit',
    name: 'inspection-unit',
    route: 'inspection-unit',
    component: () => import('@/views/client/inspection_units/Index'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved',
    navActiveLink: 'forms' }
  },
  {
    path: '/client/task_status/:keyword?/:sortBy?/:sortDirection?/:site?/:frequency?/:locations?/:supervisor?/:start?/:end?/:status?',
    name: 'task-status',
    route: 'task-status',
    component: () => import('@/views/client/task_status/Index'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' }
  },
  {
    path: '/client/service-report',
    name: 'service-report',
    route: 'service-report',
    component: () => import('@/views/client/task_status/ServiceReport'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' }
  },
  {
    // path: '/client/view-task_status/:id/:keyword?/:sortBy?/:sortDirection?/:site?/:frequency?/:page?/:start?/:end?/:status?/:group?/:assignedStatus?/:crew?',
    path: '/client/view-task_status/:id/:site?/:location?/:group?/:frequencyData?/:crew?/:start?/:end?/:tab_status?/:page?/:assign_status?',
    name: 'view-task-status',
    route: 'view-task-status',
    component: () => import('@/views/client/task_status/ViewTask'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved',
    navActiveLink: 'periodic-sub-tasks' }
  },
  {
    path: '/client/edit-periodic-task-status/:id/:keyword?/:sortBy?/:sortDirection?/:site?/:frequency?/:page?/:start?/:end?/:status?/:group?/:assignedStatus?/:crew?',
    name: 'edit-periodic-task-status',
    route: 'edit-periodic-task-status',
    component: () => import('@/views/client/periodic_tasks/EditPeriodicTaskStatus'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    /* navActiveLink: 'periodic-sub-tasks' */}
  },
  {
    // path: '/client/view-sr-task_status/:id/:keyword?/:sortBy?/:sortDirection?/:site?/:type?/:month?/:year?',
    path: '/client/view-sr-task_status/:id/:site?/:location?/:crew?/:start?/:end?/:tab_status?/:page?/:assign_status?',
    name: 'view-sr-task-status',
    route: 'view-sr-task-status',
    component: () => import('@/views/client/task_status/ViewTask'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved',
    navActiveLink: 'adhoc-tasks' }
  },
  {
    path: '/client/update-qi',
    name: 'update-qi',
    route: 'update-qi',
    component: () => import('@/views/client/assigned_qi/Index'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' }
  },
  {
    path: '/client/view-qi/:id',
    name: 'view-qi',
    route: 'view-qi',
    component: () => import('@/views/client/assigned_qi/View-qi'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved', navActiveLink: 'update-qi'}
  },
  {
    path: '/client/qi-reports',
    name: 'qi-reports',
    route: 'qi-reports',
    component: () => import('@/views/client/assigned_qi/QIReport'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved', navActiveLink: 'qi-reports'}
  },
  {
    path: '/client/checklists',
    name: 'checklists',
    route: 'checklists',
    component: () => import('@/views/client/checklists/Index'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' }
  },
  {
    path: '/client/update-checklists',
    name: 'update-checklists',
    route: 'update-checklists',
    component: () => import('@/views/client/checklists/Checklist'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' }
  },
  {
    path: '/client/view-checklist/:id',
    name: 'view-checklists',
    route: 'view-checklists',
    component: () => import('@/views/client/checklists/View'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved', navActiveLink: 'update-checklists' }
  },
  {
    path: '/client/robotics',
    name: 'robotics',
    route: 'robotics',
    component: () => import('@/views/client/robotics/Index'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' }
  },
  // {
  //   path: '/client/defects',
  //   name: 'defects',
  //   route: 'defects',
  //   component: () => import('@/views/client/defects/TaskList'),
  //   meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' }
  // },
  // {
  //   path: '/client/add-defects',
  //   name: 'add-defects',
  //   route: 'add-defects',
  //   component: () => import('@/views/client/defects/AddDefect'),
  //   meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
  //   navActiveLink: 'defects'}
  // },
  // {
  //   path: '/client/edit-defects/:id',
  //   name: 'edit-defects',
  //   route: 'edit-defects',
  //   component: () => import('@/views/client/defects/EditDefect'),
  //   meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
  //   navActiveLink: 'defects'}
  // },
  {
    path: '/client/map',
    name: 'map',
    route: 'map',
    component: () => import('@/views/client/map/Index'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' }
  },
  {
    path: '/client/timesheet',
    name: 'timesheet',
    route: 'timesheet',
    component: () => import('@/views/client/attendance/Index'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' }
  },
  {
    path: '/client/view-sites/:id',
    name: 'view-site',
    route: 'view-site',
    component: () => import('@/views/client/job_sites/ViewSite'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'job-sites'}
  },
  {
    path: '/client/holiday',
    name: 'client-holiday',
    route: 'client-holiday',
    component: () => import('@/views/client/holiday/Index'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' }
  },
  {
    path: '/client/general-awareness',
    name: 'general-awareness',
    route: 'general-awareness',
    component: () => import('@/views/client/general_awareness/Index'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved',
    navActiveLink: 'forms' }
  },
  {
    path: '/client/general-awareness/add',
    name: 'add-general-awareness',
    route: 'add-general-awareness',
    component: () => import('@/views/client/general_awareness/Add'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'forms' }
  },
  {
    path: '/client/general-awareness/edit/:id',
    name: 'edit-general-awareness',
    route: 'edit-general-awareness',
    component: () => import('@/views/client/general_awareness/Edit'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'forms' }
  },
  {
    path: '/client/equipments',
    name: 'equipments',
    route: 'equipments',
    component: () => import('@/views/client/equipments/Index'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms'}
  },
  {
    path: '/client/equipments/add',
    name: 'add-equipments',
    route: 'add-equipments',
    component: () => import('@/views/client/equipments/Add'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms'}
  },
  {
    path: '/client/equipments/edit/:id',
    name: 'edit-equipments',
    route: 'edit-equipments',
    component: () => import('@/views/client/equipments/Edit'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms'}
  },
  {
    path: '/client/view-qa-checklist/:id',
    name: 'view-qa-checklists',
    route: 'view-qa-checklists',
    component: () => import('@/views/client/checklists/ViewQA'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved', navActiveLink: 'update-checklists' }
  },
  {
    path: '/client/monthly-inspection-checklist/:site_id?',
    name: 'monthly-inspection-checklist',
    route: 'monthly-inspection-checklist',
    component: () => import('@/views/client/monthly_inspection_checklist/Add'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms'}
  },
  {
    path: '/client/monthly-inspection-checklist/add',
    name: 'add-monthly-inspection-checklist',
    route: 'add-monthly-inspection-checklist',
    component: () => import('@/views/client/monthly_inspection_checklist/Add'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms'}
  },
  {
    path: '/client/safety-inspection-questions',
    name: 'safety-inspection-questions',
    route: 'safety-inspection-questions',
    component: () => import('@/views/client/inspection_questions/Index'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms'}
  },
  {
    path: '/client/safety-inspection-questions/add',
    name: 'add-safety-inspection-questions',
    route: 'add-safety-inspection-questions',
    component: () => import('@/views/client/inspection_questions/Add'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms'}
  },
  {
    path: '/client/safety-inspection-questions/edit/:id',
    name: 'edit-safety-inspection-questions',
    route: 'edit-safety-inspection-questions',
    component: () => import('@/views/client/inspection_questions/Edit'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms'}
  },
  {
    path: '/client/create-safety-inspection/:site_id?',
    name: 'create-safety-inspection',
    route: 'create-safety-inspection',
    component: () => import('@/views/client/safety-inspection/View'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms'}
  },
  {
    path: '/client/safety-inspection-report/:site_id?',
    name: 'safety-inspection-report',
    route: 'safety-inspection-report',
    component: () => import('@/views/client/safety-inspection/Index'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms-dashboard'}
  },
  {
    path: '/client/monthly-inspection-report/:site_id?',
    name: 'monthly-inspection-report',
    route: 'monthly-inspection-report',
    component: () => import('@/views/client/monthly_inspection_checklist/Report'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms-dashboard'}
  },
  {
    path: '/client/questions',
    name: 'questions',
    route: 'questions',
    component: () => import('@/views/client/questions/Index'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms'}
  },
  {
    path: '/client/questions/add',
    name: 'add-questions',
    route: 'add-questions',
    component: () => import('@/views/client/questions/Add'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms'}
  },
  {
    path: '/client/questions/edit/:id',
    name: 'edit-questions',
    route: 'edit-questions',
    component: () => import('@/views/client/questions/Edit'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms'}
  },
  {
    path: '/client/work-essentials',
    name: 'work-essentials',
    route: 'work-essentials',
    component: () => import('@/views/client/work_essentials/Index'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms'}
  },
  {
    path: '/client/work-essentials/add',
    name: 'add-work-essentials',
    route: 'add-work-essentials',
    component: () => import('@/views/client/work_essentials/Add'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms'}
  },
  {
    path: '/client/work-essentials/edit/:id',
    name: 'edit-work-essentials',
    route: 'edit-work-essentials',
    component: () => import('@/views/client/work_essentials/Edit'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms'}
  },
  {
    path: '/client/create-toolbox-meeting/:site_id?',
    name: 'create-toolbox-meeting',
    route: 'create-toolbox-meeting',
    component: () => import('@/views/client/toolbox_meeting/Add'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms'}
  },
  {
    path: '/client/toolbox-meeting-report/:site_id?',
    name: 'toolbox-meeting-report',
    route: 'toolbox-meeting-report',
    component: () => import('@/views/client/toolbox_meeting/Report'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms-dashboard'}
  },
  {
    path: '/client/view-profile',
    name: 'view-profile',
    route: 'view-profile',
    component: () => import('@/views/client/profile/View'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' }
  },
  {
    path: '/client/change-password',
    name: 'change-password',
    route: 'change-password',
    component: () => import('@/views/client/profile/ChangePassword'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' }
  },
  {
    path: '/client/edit-profile',
    name: 'edit-profile',
    route: 'edit-profile',
    component: () => import('@/views/client/profile/Edit'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' }
  },
  {
    path: '/client/supervisor-daily-checklists/:site_id?',
    name: 'daily-checklist',
    route: 'daily-checklist',
    component: () => import('@/views/client/daily_checklists/View'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms'}
  },
  {
    path: '/client/daily-checklist-locations',
    name: 'daily-checklist-locations',
    route: 'daily-checklist-locations',
    component: () => import('@/views/client/daily_checklist_locations/Index'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms'}
  },
  {
    path: '/client/daily-checklist-locations/add',
    name: 'add-daily-checklist-locations',
    route: 'add-daily-checklist-locations',
    component: () => import('@/views/client/daily_checklist_locations/Add'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms'}
  },
  {
    path: '/client/daily-checklist-locations/edit/:id',
    name: 'edit-daily-checklist-locations',
    route: 'edit-daily-checklist-locations',
    component: () => import('@/views/client/daily_checklist_locations/Edit'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms'}
  },
  {
    path: '/client/supervisor-daily-checklists-report/:site_id?',
    name: 'daily-checklist-report',
    route: 'daily-checklist-report',
    component: () => import('@/views/client/daily_checklists/Index'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms-dashboard'}
  },
  {
    path: '/client/task-group/all-groups',
    name: 'task-groups',
    route: 'task-groups',
    component: () => import('@/views/client/task-groups/Index'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'task-groups'}
  },
  {
    path: '/client/task-group/add/:site_id',
    name: 'add-task-group',
    route: 'add-task-group',
    component: () => import('@/views/client/task-groups/Add'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'task-groups'}
  },
  {
    path: '/client/task-group/edit/:id',
    name: 'edit-task-group',
    route: 'edit-task-group',
    component: () => import('@/views/client/task-groups/Edit'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'task-groups'}
  },
  {
    path: '/client/custom-forms',
    name: 'custom-forms',
    route: 'custom-forms',
    component: () => import('@/views/client/custom_forms/Index'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms'}
  },
  {
    path: '/client/custom-forms-reports',
    name: 'custom-forms-reports',
    route: 'custom-forms-reports',
    component: () => import('@/views/client/custom_forms/Reports'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms-dashboard'}
  },
  {
    path: '/client/break-hours',
    name: 'break-hours',
    route: 'break-hours',
    component: () => import('@/views/client/breakhours/Index'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'task-groups'}
  },
  {
    path: '/client/break-hours/add',
    name: 'add-break-hours',
    route: 'add-break-hours',
    component: () => import('@/views/client/breakhours/Add'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'task-groups'}
  },
  {
    path: '/client/break-hours/edit/:id',
    name: 'edit-break-hours',
    route: 'edit-break-hours',
    component: () => import('@/views/client/breakhours/Edit'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'task-groups'}
  },
  {
    path: '/client/hand-sanitizers-checklist/:site_id?',
    name: 'hand-sanitizers-checklist',
    route: 'hand-sanitizers-checklist',
    component: () => import('@/views/client/sanitizer_checklist/Index'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms'}
  },
  {
    path: '/client/hand-sanitizer-locations/:site_id/:id?/:form?',
    name: 'hand-sanitizer-locations',
    route: 'hand-sanitizer-locations',
    component: () => import('@/views/client/hand_sanitizer_locations/Index'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms'}
  },
  {
    path: '/client/add-hand-sanitizer-locations/:site?/:building?/:form_name?',
    name: 'add-hand-sanitizer-locations',
    route: 'add-hand-sanitizer-locations',
    component: () => import('@/views/client/hand_sanitizer_locations/Add'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms'}
  },
  {
    path: '/client/edit-hand-sanitizer-locations/:id',
    name: 'edit-hand-sanitizer-locations',
    route: 'edit-hand-sanitizer-locations',
    component: () => import('@/views/client/hand_sanitizer_locations/Edit'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms'}
  },
  {
    path: '/client/hand-sanitizers-checklist-report/:site_id?',
    name: 'hand-sanitizers-checklist-report',
    route: 'hand-sanitizers-checklist-report',
    component: () => import('@/views/client/sanitizer_checklist/Report'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms-dashboard'}
  },
  {
    path: '/client/hand-sanitizer-buildings/:site_id?',
    name: 'hand-sanitizer-buildings',
    route: 'hand-sanitizer-buildings',
    component: () => import('@/views/client/hand_sanitizer_buildings/Index'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms'}
  },
  {
    path: '/client/hand-sanitizer-buildings/add/:site_id?',
    name: 'add-hand-sanitizer-buildings',
    route: 'add-hand-sanitizer-buildings',
    component: () => import('@/views/client/hand_sanitizer_buildings/Add'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms'}
  },
  {
    path: '/client/hand-sanitizer-buildings/edit/:id',
    name: 'edit-hand-sanitizer-buildings',
    route: 'edit-hand-sanitizer-buildings',
    component: () => import('@/views/client/hand_sanitizer_buildings/Edit'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms'}
  },
  {
    path: '/client/conservancy-supervisor-daily-report/:id?',
    name: 'conservancy-supervisor-daily-report',
    route: 'conservancy-supervisor-daily-report',
    component: () => import('@/views/client/conservancy_supervisor_daily_report/Index'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms'}
  },
  {
    path: '/client/conservancy-supervisor-daily-report-pdf/:site_id?',
    name: 'conservancy-supervisor-daily-report-pdf',
    route: 'conservancy-supervisor-daily-report-pdf',
    component: () => import('@/views/client/conservancy_supervisor_daily_report/Report'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms-dashboard'}
  },
  {
    path: '/client/defect-type',
    name: 'defect-type',
    route: 'defect-type',
    component: () => import('@/views/client/defect_type/Index'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms'}
  },
  {
    path: '/client/defect-type/add',
    name: 'add-defect-type',
    route: 'add-defect-type',
    component: () => import('@/views/client/defect_type/Add'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms'}
  },
  {
    path: '/client/defect-type/edit/:id',
    name: 'edit-defect-type',
    route: 'edit-defect-type',
    component: () => import('@/views/client/defect_type/Edit'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms'}
  },
  {
    path: '/client/lactation-checklist/:site_id?',
    name: 'lactation-checklist',
    route: 'lactation-checklist',
    component: () => import('@/views/client/lactation/Index'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms'}
  },
  {
    path: '/client/custom-form-settings/:site_id?',
    name: 'custom-form-settings',
    route: 'custom-form-settings',
    component: () => import('@/views/client/custom_forms/Settings'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms'}
  },
  {
    path: '/client/lactation-checklist-report/:site_id?',
    name: 'lactation-checklist-report',
    route: 'lactation-checklist-report',
    component: () => import('@/views/client/lactation/Report'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms-dashboard'}
  },
  {
    path: '/client/conservancy-supervisor-daily-report-on-public-toilet/:id?',
    name: 'conservancy-supervisor-daily-report-on-public-toilet',
    route: 'conservancy-supervisor-daily-report-on-public-toilet',
    component: () => import('@/views/client/conservancy_supervisor_daily_report_on_public_toilet/Index'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms'}
  },
  {
    path: '/client/conservancy-supervisor-daily-report-on-public-toilet-pdf/:site_id?',
    name: 'conservancy-supervisor-daily-report-on-public-toilet-pdf',
    route: 'conservancy-supervisor-daily-report-on-public-toilet-pdf',
    component: () => import('@/views/client/conservancy_supervisor_daily_report_on_public_toilet/Report'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms-dashboard'}
  },
  {
    path: '/client/defect-type-public-toilet',
    name: 'defect-type-public-toilet',
    route: 'defect-type-public-toilet',
    component: () => import('@/views/client/defect_type_public_toilet/Index'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms'}
  },
  {
    path: '/client/defect-type-public-toilet/add',
    name: 'add-defect-type-public-toilet',
    route: 'add-defect-type-public-toilet',
    component: () => import('@/views/client/defect_type_public_toilet/Add'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms'}
  },
  {
    path: '/client/defect-type-public-toilet/edit/:id',
    name: 'edit-defect-type-public-toilet',
    route: 'edit-defect-type-public-toilet',
    component: () => import('@/views/client/defect_type_public_toilet/Edit'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms'}
  },
  {
    path: '/client/mosquito-prevention-report/:id?',
    name: 'mosquito-prevention-report',
    route: 'mosquito-prevention-report',
    component: () => import('@/views/client/mosquito_prevention_report/Index'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms'}
  },
  {
    path: '/client/mosquito-prevention-report-pdf/:site_id?',
    name: 'mosquito-prevention-report-pdf',
    route: 'mosquito-prevention-report-pdf',
    component: () => import('@/views/client/mosquito_prevention_report/Report'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms-dashboard'}
  },
  {
    path: '/client/locations-for-mpr',
    name: 'locations-for-mpr',
    route: 'locations-for-mpr',
    component: () => import('@/views/client/locations_for_mpr/Index'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms'}
  },
  {
    path: '/client/locations-for-mpr/add',
    name: 'add-locations-for-mpr',
    route: 'add-locations-for-mpr',
    component: () => import('@/views/client/locations_for_mpr/Add'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms'}
  },
  {
    path: '/client/locations-for-mpr/edit/:id',
    name: 'edit-locations-for-mpr',
    route: 'edit-locations-for-mpr',
    component: () => import('@/views/client/locations_for_mpr/Edit'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms'}
  },
  {
    path: '/client/smart-toilet',
    name: 'smart-toilet',
    route: 'smart-toilet',
    component: () => import('@/views/client/smart_toilet/Index'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'smart-toilet'}
  },
  {
    path: '/client/sensor/:id',
    name: 'sensor',
    route: 'sensor',
    component: () => import('@/views/client/smart_toilet/Sensor'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'smart-toilet'}
  },
  {
    path: '/client/device-stats/:id/:device_name/:device_type/:device_id/:site/:color',
    name: 'device-stats',
    route: 'device-stats',
    component: () => import('@/views/client/smart_toilet/Stats'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'smart-toilet'}
  },
  {
    path: '/client/sensor-overview/:id/:device_name/:device_type/:device_id',
    name: 'sensor-overview',
    route: 'sensor-overview',
    component: () => import('@/views/client/smart_toilet/SensorOverview'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'smart-toilet'}
  },
  {
    path: '/client/sensor-stats/:device_id/:sensor_name/:sensor_type/:sensor_id/:color',
    name: 'sensor-stats',
    route: 'sensor-stats',
    component: () => import('@/views/client/smart_toilet/SensorStats'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'smart-toilet'}
  },
  {
    path: '/client/device-report',
    name: 'device-report',
    route: 'device-report',
    // component: () => import('@/views/client/smart_toilet/DeviceReport'), // commented in favour new page 
    component: () => import('@/views/client/smart_toilet/DeviceReport_temp'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'device-report'}
  },
  {
    path: '/client/device-report-temp',
    name: 'device-report-temp',
    route: 'device-report-temp',
    component: () => import('@/views/client/smart_toilet/DeviceReport_temp'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'device-report'}
  },
  {
    path: '/client/custom-form-validate-settings',
    name: 'custom-form-validate-settings',
    route: 'custom-form-validate-settings',
    component: () => import('@/views/client/custom_form_settings/Index'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms'}
  },
  {
    path: '/client/custom-form-site-validate-settings',
    name: 'custom-form-site-validate-settings',
    route: 'custom-form-site-validate-settings',
    component: () => import('@/views/client/custom_form_site_settings/Index'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms'}
  },
  {
    path: '/client/qr-categories',
    name: 'qr-categories',
    route: 'qr-categories',
    component: () => import('@/views/client/qr_categories/Index'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved'}
  },
  {
    path: '/client/qr-categories/add',
    name: 'add-qr-categories',
    route: 'add-qr-categories',
    component: () => import('@/views/client/qr_categories/Add'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'qr-categories'}
  },
  {
    path: '/client/qr-categories/edit/:id',
    name: 'edit-qr-categories',
    route: 'edit-qr-categories',
    component: () => import('@/views/client/qr_categories/Edit'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'qr-categories'}
  },
  {
    path: '/client/active-qr',
    name: 'active-qr',
    route: 'active-qr',
    component: () => import('@/views/client/qr_categories/active_qr'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'qr-categories'}
  },
  {

    path: '/client/bottle_hand_sanitizers/:id?',
    name: 'bottle-hand-sanitizers-checklist',
    route: 'bottle-hand-sanitizers-checklist',
    component: () => import('@/views/client/bottle_hand_sanitizers/Index'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms'}
  },
  {
    path: '/client/bottle-hand-sanitizers-checklist-report/:site_id?',
    name: 'bottle-hand-sanitizers-checklist-report',
    route: 'bottle-hand-sanitizers-checklist-report',
    component: () => import('@/views/client/bottle_hand_sanitizers/Report'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms-dashboard'}
  },
  {
    path: '/client/qr-feedback-overview',
    name: 'qr-overview',
    route: 'qr-overview',
    component: () => import('@/views/client/qr_categories/Overview'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved'}
  },
  {
    path: '/client/hand-sanitizers-battery/:id?',
    name: 'hand-sanitizers-battery',
    route: 'hand-sanitizers-battery',
    component: () => import('@/views/client/sanitizer_battery/Index'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved', navActiveLink: 'custom-forms' }
  },
  {
    path: '/client/hand-sanitizers-battery-report/:site_id?',
    name: 'hand-sanitizers-battery-report',
    route: 'hand-sanitizers-battery-report',
    component: () => import('@/views/client/sanitizer_battery/Report'),
    meta: { requiresAuth: true, hideForAuth:false, showFor: 'client', accountStatus:'approved', navActiveLink: 'custom-forms-dashboard' }
  },{
    path: '/client/hand-sanitizers-staircase/:id?',
    name: 'hand-sanitizers-staircase',
    route: 'hand-sanitizers-staircase',
    component: () => import('@/views/client/sanitizer_staircase/Index'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved', navActiveLink: 'custom-forms' }
  },
  {
    path: '/client/hand-sanitizers-staircase-report/:site_id?',
    name: 'hand-sanitizers-staircase-report',
    route: 'hand-sanitizers-staircase-report',
    component: () => import('@/views/client/sanitizer_staircase/Report'),
    meta: { requiresAuth: true, hideForAuth:false, showFor: 'client', accountStatus:'approved', navActiveLink: 'custom-forms-dashboard' }
  },
  {
    path: '/client/admin-wipes/:id?',
    name: 'admin-wipes-checklist',
    route: 'admin-wipes-checklist',
    component: () => import('@/views/client/admin_wipes/Index'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms'}
  },
  {
    path: '/client/admin-wipes-report/:site_id?',
    name: 'admin-wipes-checklist-report',
    route: 'admin-wipes-checklist-report',
    component: () => import('@/views/client/admin_wipes/Report'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms-dashboard'}
  },
  {
    path: '/client/meeting-room-defect/:id?',
    name: 'meeting-room-defect',
    route: 'meeting-room-defect',
    component: () => import('@/views/client/meeting_room_defects/Index'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms'}
  },
  {
    path: '/client/meeting-room-defect-report',
    name: 'meeting-room-defect-report',
    route: 'meeting-room-defect-report',
    component: () => import('@/views/client/meeting_room_defects/Report'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms-dashboard'}
  },
  {
    path: '/client/staircase-hand-sanitizer/:id?',
    name: 'staircase-hand-sanitizer',
    route: 'staircase-hand-sanitizer',
    component: () => import('@/views/client/staircase_hand_sanitizer/Index'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms'}
  },
  {
    path: '/client/staircase-hand-sanitizer-report/:site_id?',
    name: 'staircase-hand-sanitizer-report',
    route: 'staircase-hand-sanitizer-report',
    component: () => import('@/views/client/staircase_hand_sanitizer/Report'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms-dashboard'}
  },
  {
    path: '/client/marker-pen/:id?',
    name: 'marker-pen',
    route: 'marker-pen',
    component: () => import('@/views/client/marker_pens/Index'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms'}
  },
  {
    path: '/client/marker-pen-report/:site_id?',
    name: 'marker-pen-report',
    route: 'marker-pen-report',
    component: () => import('@/views/client/marker_pens/Report'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms-dashboard'}
  },
  {
    path: '/client/stock-inventory-form/:id?',
    name: 'stock-inventory-form',
    route: 'stock-inventory-form',
    component: () => import('@/views/client/stock_inventory_form/Index'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms'}
  },
  {
    path: '/client/stock-inventory-form-report',
    name: 'stock-inventory-form-report',
    route: 'stock-inventory-form-report',
    component: () => import('@/views/client/stock_inventory_form/Report'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms-dashboard'}
  },
  {
    path: '/client/stock-inventory-items/:site_id?',
    name: 'stock-inventory-items',
    route: 'stock-inventory-items',
    component: () => import('@/views/client/stock_inventory_items/Index'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms'}
  },
  {
    path: '/client/add-stock-inventory-items/:site_id?',
    name: 'add-stock-inventory-items',
    route: 'add-stock-inventory-items',
    component: () => import('@/views/client/stock_inventory_items/Add'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms'}
  },
  {
    path: '/client/edit-stock-inventory-item/:id',
    name: 'edit-stock-inventory-item',
    route: 'edit-stock-inventory-item',
    component: () => import('@/views/client/stock_inventory_items/Edit'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms'}
  },
  {
    path: '/client/sanitary-bin/:id?',
    name: 'pedal-bin',
    route: 'pedal-bin',
    component: () => import('@/views/client/pedal_bin/Index'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms'}
  },
  {
    path: '/client/sanitary-bin-report/:site_id?',
    name: 'pedal-bin-report',
    route: 'pedal-bin-report',
    component: () => import('@/views/client/pedal_bin/Report'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms-dashboard'}
  },
  {
    path: '/client/total-flash-time/:site_id?',
    name: 'total-flash-time',
    route: 'total-flash-time',
    component: () => import('@/views/client/total_flash_time/Index'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms'}
  },
  {
    path: '/client/warning-letter/:id?',
    name: 'warning-letter',
    route: 'warning-letter',
    component: () => import('@/views/client/warning_letter/Index'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms'}
  },
  {
    path: '/client/total-flash-time-report/:site_id?',
    name: 'total-flash-time-report',
    route: 'total-flash-time-report',
    component: () => import('@/views/client/total_flash_time/Report'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms'}
  },
  {
    path: '/client/warning-letter-report/:site_id?',
    name: 'warning-letter-report',
    route: 'warning-letter-report',
    component: () => import('@/views/client/warning_letter/Report'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms-dashboard'}
  },
  {
    path: '/client/soap-dispenser/:id?',
    name: 'soap-dispenser',
    route: 'soap-dispenser',
    component: () => import('@/views/client/soap_dispenser/Index'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms-dashboard'}
  },
  {
    path: '/client/misconduct',
    name: 'misconduct',
    route: 'misconduct',
    component: () => import('@/views/client/misconduct/Index'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms'}
  },
  {
    path: '/client/soap-dispenser-report/:site_id?',
    name: 'soap-dispenser-report',
    route: 'soap-dispenser-report',
    component: () => import('@/views/client/soap_dispenser/Report'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms-dashboard'}
  },
  {
    path: '/client/floor-traps/:id?',
    name: 'floor-traps',
    route: 'floor-traps',
    component: () => import('@/views/client/floor_traps/Index'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms-dashboard'}
  },
  {
    path: '/client/misconduct/add',
    name: 'add-misconduct',
    route: 'add-misconduct',
    component: () => import('@/views/client/misconduct/Add'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms'}
  },
  {
    path: '/client/floor-traps-report/:site_id?',
    name: 'floor-traps-report',
    route: 'floor-traps-report',
    component: () => import('@/views/client/floor_traps/Report'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms-dashboard'}
  },
  {
    path: '/client/toilet-seat-sanitizer/:site_id?',
    name: 'toilet-seat-sanitizer',
    route: 'toilet-seat-sanitizer',
    component: () => import('@/views/client/toilet_seat_sanitizer/Index'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms-dashboard'}
  },
  {
    path: '/client/misconduct/edit/:id',
    name: 'edit-misconduct',
    route: 'edit-misconduct',
    component: () => import('@/views/client/misconduct/Edit'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms'}
  },
  {
    path: '/client/toilet-seat-sanitizer-report/:site_id?',
    name: 'toilet-seat-sanitizer-report',
    route: 'toilet-seat-sanitizer-report',
    component: () => import('@/views/client/toilet_seat_sanitizer/Report'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms-dashboard'}
  },
  {
    path: '/client/air-freshener/:id?',
    name: 'air-freshener',
    route: 'air-freshener',
    component: () => import('@/views/client/air_freshener/Index'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms-dashboard'}
  },
  {
    path: '/client/feedback-panel',
    name: 'feedback-panel',
    route: 'feedback-panel',
    component: () => import('@/views/client/feedback_panel/Index'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved'}
  },
  {
    path: '/client/air-freshener-report/:site_id?',
    name: 'air-freshener-report',
    route: 'air-freshener-report',
    component: () => import('@/views/client/air_freshener/Report'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms-dashboard'}
  },
  {
    path: '/client/mark-nfc-attendance',
    name: 'NfcAttendance',
    route: 'NfcAttendance',
    component: () => import('@/views/client/attendance/MarkNfcAttendance'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' }
  },
  {
    path: '/client/toilet-inspection-checklist/:id?',
    name: 'toilet-inspection-checklist',
    route: 'toilet-inspection-checklist',
    component: () => import('@/views/client/toilet_inspection/Index'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms'}
  },
  {
    path: '/client/toilet-inspection-report/:site_id?',
    name: 'toilet-inspection-checklist-report',
    route: 'toilet-inspection-checklist-report',
    component: () => import('@/views/client/toilet_inspection/Report'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms-dashboard'}
  },
  {
    path: '/client/toilet-item/:site_id?',
    name: 'toilet-item',
    route: 'toilet-item',
    component: () => import('@/views/client/toilet_item/Index'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms'}
  },
  {
    path: '/client/toilet-item/add/:site_id?',
    name: 'add-toilet-item',
    route: 'add-toilet-item',
    component: () => import('@/views/client/toilet_item/Add'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms'}
  },
  {
    path: '/client/toilet-item/edit/:id',
    name: 'edit-toilet-item',
    route: 'edit-toilet-item',
    component: () => import('@/views/client/toilet_item/Edit'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms'}
  },
  {
    path: '/client/quality-safety-assessment-form/:id?',
    name: 'quality-safety',
    route: 'quality-safety',
    component: () => import('@/views/client/quality_and_safety_assessment/Index'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms'}
  },
  {
    path: '/client/quality-safety-locations/:site_id?',
    name: 'quality-safety-locations',
    route: 'quality-safety-locations',
    component: () => import('@/views/client/quality_safety_locations/Index'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms'}
  },
  {
    path: '/client/add-quality-safety-location/:site_id?',
    name: 'add-quality-safety-location',
    route: 'add-quality-safety-location',
    component: () => import('@/views/client/quality_safety_locations/Add'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms'}
  },
  {
    path: '/client/edit-quality-safety-location/:id',
    name: 'edit-quality-safety-location',
    route: 'edit-quality-safety-location',
    component: () => import('@/views/client/quality_safety_locations/Edit'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms'}
  },
  {
    path: '/client/quality-safety-locations-items/:id/:site_id',
    name: 'quality-safety-locations-items',
    route: 'quality-safety-locations-items',
    component: () => import('@/views/client/quality_safety_locations_items/Index'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms'}
  },
  {
    path: '/client/add-quality-safety-location-items/:site_id/:id',
    name: 'add-quality-safety-location-item',
    route: 'add-quality-safety-location-item',
    component: () => import('@/views/client/quality_safety_locations_items/Add'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms'}
  },
  {
    path: '/client/edit-quality-safety-location-items/:id',
    name: 'edit-quality-safety-location-item',
    route: 'edit-quality-safety-location-item',
    component: () => import('@/views/client/quality_safety_locations_items/Edit'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms'}
  },
  {
    path: '/client/quality-safety-report/:site_id?',
    name: 'quality-safety-report',
    route: 'quality-safety-report',
    component: () => import('@/views/client/quality_and_safety_assessment/Report'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms-dashboard'}
  },
  {
    path: '/client/feedback-request/:keyword?/:sortBy?/:sortDirection?/:site?/:location?/:crew?/:status?/:tab_status?/:start?/:end?/:assign_status?',
    name: 'feedback-request',
    route: 'feedback-request',
    component: () => import('@/views/client/feedback_request/Index'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' }
  },
  {
    path: '/client/feedback-request-analytics',
    name: 'feedback-request-analytics',
    route: 'feedback-request-analytics',
    component: () => import('@/views/client/feedback_request/Analytics'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' }
  },
  {
    path: '/client/export-feedback-request-analytics/:site?/:location?/:crew?/:status?/:start?/:end?/:site_name?/:location_name?/:date_format?/:crew_name?/:assign_status',
    name: 'export-feedback-request-analytics',
    route: 'export-feedback-request-analytics',
    component: () => import('@/views/client/feedback_request/AnalyticsExport'),
    meta: { layout: 'full', requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved'}
  },
  {
    path: '/client/add-feedback-request',
    name: 'add-feedback-request',
    route: 'add-feedback-request',
    component: () => import('@/views/client/feedback_request/Add'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'feedback-request'}
  },
  {
    path: '/client/edit-feedback-request/:id',
    name: 'edit-feedback-request',
    route: 'edit-feedback-request',
    component: () => import('@/views/client/feedback_request/Edit'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'feedback-request'}
  },
  {
    path: '/client/view-feedback-request-status/:id/:keyword?/:sortBy?/:sortDirection?/:site?/:location?/:crew?/:status?/:tab_status?/:start?/:end?/:assign_status?',
    name: 'view-feedback-request-status',
    route: 'view-feedback-request-status',
    component: () => import('@/views/client/feedback_request/View'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved',
    navActiveLink: 'feedback-request' }
  },
  {
    path: '/client/edit-feedback-request-status/:id/:keyword?/:sortBy?/:sortDirection?/:site?/:location?/:crew?/:status?/:tab_status?/:start?/:end?',
    name: 'edit-feedback-request-status',
    route: 'edit-feedback-request-status',
    component: () => import('@/views/client/feedback_request/EditFeedbackRequestStatus'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'feedback-request' }
  },
  {
    path: '/client/feedback-request-report',
    name: 'feedback-request-report',
    route: 'feedback-request-report',
    component: () => import('@/views/client/feedback_request/Report'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' }
  },
  {
    // path: '/client/defects/:keyword?/:sortBy?/:sortDirection?/:site?/:month?/:year?',
    path: '/client/defects/:site?/:location?/:crew?/:start?/:end?/:tab_status?',
    name: 'defects',
    route: 'defects',
    component: () => import('@/views/client/defect_request/Index'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' }
  },
  {
    path: '/client/add-defects',
    name: 'add-defects',
    route: 'add-defects',
    component: () => import('@/views/client/defect_request/Add'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'defects'}
  },
  {
    path: '/client/edit-defects/:id',
    name: 'edit-defects',
    route: 'edit-defects',
    component: () => import('@/views/client/defect_request/Edit'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'defects'}
  },
  {
    // path: '/client/view-defect-status/:id/:keyword?/:sortBy?/:sortDirection?/:site?/:month?/:year?',
    path: '/client/view-defect-status/:id/:site?/:location?/:crew?/:start?/:end?/:tab_status?',
    name: 'view-defect-status',
    route: 'view-defect-status',
    component: () => import('@/views/client/defect_request/View'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved',
    navActiveLink: 'defects' }
  },
  {
    path: '/client/edit-defect-status/:id/:keyword?/:sortBy?/:sortDirection?/:site?/:month?/:year?',
    name: 'edit-defect-status',
    route: 'edit-defect-status',
    component: () => import('@/views/client/defect_request/EditDefectRequestStatus'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'defects' }
  },
  {
    path: '/client/defect-report',
    name: 'defect-report',
    route: 'defect-report',
    component: () => import('@/views/client/defect_request/Report'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' }
  },
  {
    path: '/client/custom-forms-dashboard',
    name: 'custom-forms-dashboard',
    route: 'custom-forms-dashboard',
    component: () => import('@/views/client/custom_forms/Dashboard'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved'}
  },
  {
    path: '/client/form-areas/:site/:build/:loc_id/:name',
    name: 'form-areas',
    route: 'form-areas',
    component: () => import('@/views/client/form_area/AreaList'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved',
    navActiveLink: 'custom_forms'}
  },
  {
    path: '/client/flash-times/:id?',
    name: 'flash-time',
    route: 'flash-time',
    component: () => import('@/views/client/flash_times/Index'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms'}
  },
  {
    path: '/client/flash-item/:site_id?',
    name: 'flash-item',
    route: 'flash-item',
    component: () => import('@/views/client/flash_item/Index'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms'}
  },
  {
    path: '/client/add-flash-item/:site_id?',
    name: 'add-flash-item',
    route: 'add-flash-item',
    component: () => import('@/views/client/flash_item/Add'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms'}
  },
  {
    path: '/client/edit-flash-item/:id',
    name: 'edit-flash-item',
    route: 'edit-flash-item',
    component: () => import('@/views/client/flash_item/Edit'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms'}
  },
  {
    path: '/client/off-reports',
    name: 'off-reports',
    route: 'off-reports',
    component: () => import('@/views/client/custom_forms/OffReports'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms-dashboard'}
  },{
    path: '/client/admin-wipes-night/:id?',
    name: 'admin-wipes-night-checklist',
    route: 'admin-wipes-night-checklist',
    component: () => import('@/views/client/admin_wipes_night/Index'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms'}
  },
  {
    path: '/client/admin-wipes-night-report/:site_id?',
    name: 'admin-wipes-night-checklist-report',
    route: 'admin-wipes-night-checklist-report',
    component: () => import('@/views/client/admin_wipes_night/Report'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms-dashboard'}
  },
  {

    path: '/client/bottle_hand_sanitizers_night/:id?',
    name: 'bottle-hand-sanitizers-night-checklist',
    route: 'bottle-hand-sanitizers-night-checklist',
    component: () => import('@/views/client/bottle_hand_sanitizers_night/Index'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms'}
  },
  {
    path: '/client/bottle-hand-sanitizers-night-checklist-report/:site_id?',
    name: 'bottle-hand-sanitizers-night-checklist-report',
    route: 'bottle-hand-sanitizers-night-checklist-report',
    component: () => import('@/views/client/bottle_hand_sanitizers_night/Report'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms-dashboard'}
  },
  {
    path: '/client/feedback-system-location',
    name: 'feedback-system-location',
    route: 'feedback-system-location',
    component: () => import('@/views/client/location/Index'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: ''}
  },
  {
    path: '/client/location-category',
    name: 'location-category',
    route: 'location-category',
    component: () => import('@/views/client/location/Category'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: ''}
  },
  {
    path: '/client/location-active-qr',
    name: 'location-active-qr',
    route: 'location-active-qr',
    component: () => import('@/views/client/location/active_qr'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'feedback-system-location'}
  },
  {
    path: '/client/location-qr-categories/add',
    name: 'location-qr-category-add',
    route: 'location-qr-category-add',
    component: () => import('@/views/client/location/Add'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'location-category'}
  },
  {
    path: '/client/edit-location-qr-categories/:id',
    name: 'location-qr-category-edit',
    route: 'location-qr-category-edit',
    component: () => import('@/views/client/location/Edit'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'location-category'}
  },
  {
    path: '/client/site-group',
    name: 'site-group',
    route: 'site-group',
    component: () => import('@/views/client/site-groups/Index'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved'}
  },
  {
    path: '/client/add-site-group',
    name: 'add-site-group',
    route: 'add-site-group',
    component: () => import('@/views/client/site-groups/Add'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'site-group'}
  },
  {
    path: '/client/edit-site-group/:id',
    name: 'edit-site-group',
    route: 'edit-site-group',
    component: () => import('@/views/client/site-groups/Edit'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'site-group'}
  },
  {
    path: '/client/flash-time-report/:site_id?',
    name: 'flash-time-report',
    route: 'flash-time-report',
    component: () => import('@/views/client/flash_times/Report'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms-dashboard'}
  },
  {
    path: '/client/analytics',
    name: 'analytics',
    route: 'analytics',
    component: () => import('@/views/client/analytics/Index'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved'}
  },
  {
    path: '/client/schedule-work-analytics',
    name: 'schedule-work-analytics',
    route: 'schedule-work-analytics',
    component: () => import('@/views/client/periodic_tasks/Analytics'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved'}
  },
  {
    path: '/client/export-schedule-work-analytics/:site?/:location?/:frequency?/:group?/:crew?/:status?/:start?/:end?/:site_name?/:location_name?/:crew_name?/:date_format',
    name: 'export-schedule-work-analytics',
    route: 'export-schedule-work-analytics',
    component: () => import('@/views/client/periodic_tasks/AnalyticsExport'),
    meta: { layout: 'full', requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved'}
  },
  {
    path: '/client/task-analytics',
    name: 'task-analytics',
    route: 'task-analytics',
    component: () => import('@/views/client/tasks/Analytics'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved'}
  },
  {
    path: '/client/export-task-analytics/:site?/:location?/:task_type?/:frequency?/:crew?/:status?/:start?/:end?/:site_name?/:location_name?/:date_format?/:crew_name',
    name: 'export-task-analytics',
    route: 'export-task-analytics',
    component: () => import('@/views/client/tasks/AnalyticsExport'),
    meta: { layout: 'full', requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved'}
  },
  {
    path: '/client/external-checklist/:id?',
    name: 'external-checklist',
    route: 'external-checklist',
    component: () => import('@/views/client/external_checklists/Index'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms'}
  },
  {
    path: '/client/external-checklist-report',
    name: 'external-checklist-report',
    route: 'external-checklist-report',
    component: () => import('@/views/client/external_checklists/Report'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms-dashboard'}
  },
  {
    path: '/client/sections/:site_id?',
    name: 'sections',
    route: 'sections',
    component: () => import('@/views/client/sections/Index'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms'}
  },
  {
    path: '/client/add-sections/:site_id?',
    name: 'add-sections',
    route: 'add-sections',
    component: () => import('@/views/client/sections/Add'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms'}
  },
  {
    path: '/client/edit-sections/:id',
    name: 'edit-sections',
    route: 'edit-sections',
    component: () => import('@/views/client/sections/Edit'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'custom-forms'}
  },
  {
    path: '/client/summary',
    name: 'summary',
    route: 'summary',
    component: () => import('@/views/client/Summary/Index'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved'}
  },
  {
    path: '/client/feedback-system-qr',
    name: 'feedback-system-qr',
    route: 'feedback-system-qr',
    component: () => import('@/views/client/feedback_system_qr/Index'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved'}
  },
  {
    path: '/client/quality-management-inspection-area/:site_id?',
    name: 'quality-management-inspection-area',
    route: 'quality-management-inspection-area',
    component: () => import('@/views/client/inspection_area/Index'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved', 
    navActiveLink: 'quality-management-inspection-area' }
  },
  {
    path: '/client/quality-management-elements/:site_id?',
    name: 'quality-management-elements',
    route: 'quality-management-elements',
    component: () => import('@/views/client/unit_elements/ElementList'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved', 
    navActiveLink: 'quality-management-inspection-area' }
  },
  {
    path: '/client/add-quality-management-element/:site_id',
    name: 'add-qm-element',
    route: 'add-qm-element',
    component: () => import('@/views/client/unit_elements/AddElement'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'quality-management-inspection-area'}
  },
  {
    path: '/client/edit-quality-management-element/:id',
    name: 'edit-qm-element',
    route: 'edit-qm-element',
    component: () => import('@/views/client/unit_elements/EditElement'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'quality-management-inspection-area'}
  },
  {
    path: '/client/quality-management-units/:site_id?',
    name: 'quality-management-units',
    route: 'quality-management-units',
    component: () => import('@/views/client/qm_units/Index'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved', 
    navActiveLink: 'quality-management-inspection-area' }
  },
  {
    path: '/client/add-quality-management-unit/:site_id',
    name: 'add-qm-unit',
    route: 'add-qm-unit',
    component: () => import('@/views/client/qm_units/Add'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'quality-management-inspection-area'}
  },
  {
    path: '/client/edit-quality-management-unit/:id',
    name: 'edit-qm-unit',
    route: 'edit-qm-unit',
    component: () => import('@/views/client/qm_units/Edit'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'quality-management-inspection-area'}
  },
  {
    path: '/client/quality-management-general-awareness/:site_id?',
    name: 'quality-management-general-awareness',
    route: 'quality-management-general-awareness',
    component: () => import('@/views/client/qm_general_awareness/Index'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved', 
    navActiveLink: 'quality-management-inspection-area' }
  },
  {
    path: '/client/add-quality-management-general-awareness/:site_id',
    name: 'add-qm-general_awareness',
    route: 'add-qm-general_awareness',
    component: () => import('@/views/client/qm_general_awareness/Add'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'quality-management-inspection-area'}
  },
  {
    path: '/client/edit-quality-management-general-awareness/:id',
    name: 'edit-qm-general_awareness',
    route: 'edit-qm-general_awareness',
    component: () => import('@/views/client/qm_general_awareness/Edit'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' , 
    navActiveLink: 'quality-management-inspection-area'}
  },
  
  {
    path: '/client/qm-qi',
    name: 'qm-qi',
    route: 'qm-qi',
    component: () => import('@/views/client/quality_management/QIReport'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved'}
  },
  {
    path: '/client/qm-qi-validation-settings',
    name: 'qm-qi-validation-settings',
    route: 'qm-qi-validation-settings',
    component: () => import('@/views/client/quality_management_settings/Index'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved', navActiveLink: 'qm-qi'}
  },
  {
    path: '/client/qm-add-qi/:id?',
    name: 'add-qi',
    route: 'add-qi',
    component: () => import('@/views/client/quality_management/AddQI'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved', navActiveLink: 'qm-qi'}
  },
  {
    path: '/client/qm-edit-qi/:id?',
    name: 'edit-qi',
    route: 'edit-qi',
    component: () => import('@/views/client/quality_management/EditQI'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved', navActiveLink: 'qm-qi'}
  },
  {
    path: '/client/qm-qi-monthly-average',
    name: 'qm-qi-monthly-average',
    route: 'qm-qi-monthly-average',
    component: () => import('@/views/client/quality_management/monthly_average'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved'}
  },
  {
    path: '/client/suppliers',
    name: 'suppliers',
    route: 'suppliers',
    component: () => import('@/views/client/suppliers/Index'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved'}
  },
  {
    path: '/client/add-supplier',
    name: 'add-supplier',
    route: 'add-supplier',
    component: () => import('@/views/client/suppliers/AddSupplier'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved', 
    navActiveLink: 'suppliers' }
  },
  {
    path: '/client/edit-supplier/:id',
    name: 'edit-supplier',
    route: 'edit-supplier',
    component: () => import('@/views/client/suppliers/EditSupplier'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved', 
    navActiveLink: 'suppliers' }
  },
  {
    path: '/client/supplier-category/:supplier_id?',
    name: 'supplier-category',
    route: 'supplier-category',
    component: () => import('@/views/client/suppliers/Category'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved', 
    navActiveLink: 'suppliers' }
  },
  {
    path: '/client/add-supplier-category/:supplier_id?',
    name: 'add-supplier-category',
    route: 'add-supplier-category',
    component: () => import('@/views/client/suppliers/AddSupplierCategory'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved', 
    navActiveLink: 'suppliers' }
  },
  {
    path: '/client/edit-supplier-category/:id',
    name: 'edit-supplier-category',
    route: 'edit-supplier-category',
    component: () => import('@/views/client/suppliers/EditSupplierCategory'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved', 
    navActiveLink: 'suppliers' }
  },
  {
    path: '/client/supplier-sub-category/:supplier_id?/:category_id?',
    name: 'supplier-sub-category',
    route: 'supplier-sub-category',
    component: () => import('@/views/client/suppliers/SubCategory'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved', 
    navActiveLink: 'suppliers' }
  },
  {
    path: '/client/add-supplier-sub-category/:supplier_id?/:category_id?',
    name: 'add-supplier-sub-category',
    route: 'add-supplier-sub-category',
    component: () => import('@/views/client/suppliers/AddSupplierSubCategory'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved', 
    navActiveLink: 'suppliers' }
  },
  {
    path: '/client/edit-supplier-sub-category/:id',
    name: 'edit-supplier-sub-category',
    route: 'edit-supplier-sub-category',
    component: () => import('@/views/client/suppliers/EditSupplierSubCategory'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved', 
    navActiveLink: 'suppliers' }
  },
  {
    path: '/client/supplier-items/:supplier_id?/:category_id?/:sub_category_id?',
    name: 'supplier-items',
    route: 'supplier-items',
    component: () => import('@/views/client/supplier_items/Index'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved'}
  },
  {
    path: '/client/add-supplier-item/:supplier_id?/:category_id?/:sub_category_id?',
    name: 'add-supplier-item',
    route: 'add-supplier-item',
    component: () => import('@/views/client/supplier_items/Add'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved', 
    navActiveLink: 'supplier-items' }
  },
  {
    path: '/client/edit-supplier-item/:id',
    name: 'edit-supplier-item',
    route: 'edit-supplier-item',
    component: () => import('@/views/client/supplier_items/Edit'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved', 
    navActiveLink: 'supplier-items' }
  },
  {
    path: '/client/import-inventory-items',
    name: 'import-inventory-items',
    route: 'import-inventory-items',
    component: () => import('@/views/client/supplier_items/Import'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved'}
  },
  {
    path: '/client/project-site-items',
    name: 'project-site-items',
    route: 'project-site-items',
    component: () => import('@/views/client/project_site_items/Index'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved' }
  },
  {
    path: '/client/project-site-items-list/:site_id?',
    name: 'project-site-items-list',
    route: 'project-site-items-list',
    component: () => import('@/views/client/project_site_items/List'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved', 
    navActiveLink: 'project-site-items' }
  },
  {
    path: '/client/qm-qa',
    name: 'qm-qa',
    route: 'qm-qa',
    component: () => import('@/views/client/quality_management_qa/Report'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved'}
  },
  {
    path: '/client/qm-add-qa/:id?',
    name: 'add-qa',
    route: 'add-qa',
    component: () => import('@/views/client/quality_management_qa/Add'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved', navActiveLink: 'qm-qa'}
  },
  {
    path: '/client/qm-edit-qa/:id?',
    name: 'edit-qa',
    route: 'edit-qa',
    component: () => import('@/views/client/quality_management_qa/Edit'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved', navActiveLink: 'qm-qa'}
  },
  {
    path: '/client/qm-qa-monthly-average',
    name: 'qm-qa-monthly-average',
    route: 'qm-qa-monthly-average',
    component: () => import('@/views/client/quality_management_qa/monthly_average'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved'}
  },
  {
    path: '/client/inventory-order-report',
    name: 'inventory-order-report',
    route: 'inventory-order-report',
    component: () => import('@/views/client/order/Report'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved'}
  },
  {
    path: '/client/inventory-order',
    name: 'inventory-order',
    route: 'inventory-order',
    component: () => import('@/views/client/order/Index'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved'}
  },
  {
    path: '/client/inventory-purchase-order',
    name: 'inventory-purchase-order',
    route: 'inventory-purchase-order',
    component: () => import('@/views/client/order/InventoryPurchaseOrder'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved'}
  },
  {
    path: '/client/add-inventory-order/:id?',
    name: 'add-inventory-order',
    route: 'add-inventory-order',
    component: () => import('@/views/client/order/Add'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved', 
    navActiveLink: 'inventory-order'}
  },
  {
    path: '/client/update-inventory-order/:id?',
    name: 'update-inventory-order',
    route: 'update-inventory-order',
    component: () => import('@/views/client/order/Update'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved', 
    navActiveLink: 'inventory-order'}
  },
  {
    path: '/client/inventory-area-kanban-board',
    name: 'inventory-area-kanban-board',
    route: 'inventory-area-kanban-board',
    component: () => import('@/views/client/inventory_area/Kanban'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved'}
  },
  {
    path: '/client/inventory-area',
    name: 'inventory-area',
    route: 'inventory-area',
    component: () => import('@/views/client/inventory_area/Index'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved'}
  },
  {
    path: '/client/add-inventory-area',
    name: 'add-inventory-area',
    route: 'add-inventory-area',
    component: () => import('@/views/client/inventory_area/Add'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved',navActiveLink: 'inventory-area'}
  },
  {
    path: '/client/edit-inventory-area/:id',
    name: 'edit-inventory-area',
    route: 'edit-inventory-area',
    component: () => import('@/views/client/inventory_area/Edit'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved',navActiveLink: 'inventory-area'}
  },
  {
    path: '/client/inventory-area-users/:area_id?',
    name: 'inventory-area-user',
    route: 'inventory-area-user',
    component: () => import('@/views/client/inventory_area/AreaUser'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved',navActiveLink: 'inventory-area'}
  },
  {
    path: '/client/add-inventory-area-user/:area_id?',
    name: 'add-inventory-area-user',
    route: 'add-inventory-area-user',
    component: () => import('@/views/client/inventory_area/AddAreaUser'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved',navActiveLink: 'inventory-area'}
  },
  {
    path: '/client/edit-inventory-area-user/:id',
    name: 'edit-inventory-area-user',
    route: 'edit-inventory-area-user',
    component: () => import('@/views/client/inventory_area/EditAreaUser'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved',navActiveLink: 'inventory-area'}
  },
  {
    path: '/client/area-clusters/:area_id?',
    name: 'area-cluster',
    route: 'area-cluster',
    component: () => import('@/views/client/cluster/Index'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved',navActiveLink: 'inventory-area'}
  },
  {
    path: '/client/add-area-cluster/:area_id?',
    name: 'add-area-cluster',
    route: 'add-area-cluster',
    component: () => import('@/views/client/cluster/Add'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved',navActiveLink: 'inventory-area'}
  },
  {
    path: '/client/edit-area-cluster/:area_id/:id',
    name: 'edit-area-cluster',
    route: 'edit-area-cluster',
    component: () => import('@/views/client/cluster/Edit'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved',navActiveLink: 'inventory-area'}
  },
  {
    path: '/client/moq-report/:id?',
    name: 'moq-report',
    route: 'moq-report',
    component: () => import('@/views/client/moq/Report.vue'),
    meta: { requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus: 'approved'}
  }
]
