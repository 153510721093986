export default [
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/ClientLogin.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      requiresAuth: false, 
      hideForAuth:false
    },
  },
  {
    path: '/admin-login',
    name: 'admin-login',
    component: () => import('@/views/AdminLogin.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      requiresAuth: false, 
      hideForAuth:false
    },
  },
  {
    path: '/change-account-status',
    name: 'change-account-status',
    component: () => import('@/views/ChangeAccountPassword.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      requiresAuth: true, 
      hideForAuth:false,
      changePassword:true
    },
  },
  {
    path: '/feedback/:id',
    name: 'feedback',
    component: () => import('@/views/Feedback.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      requiresAuth: false, 
      hideForAuth:false
    },
  },
  {
    path: '/client/export-pdf/:site?/:location?/:start?/:end?/:site_name?/:location_name?/:date_format?/:chart_type',
    name: 'export-pdf',
    route: 'export-pdf',
    component: () => import('@/views/AnalyticsExport'),
    meta: { layout: 'full', resource: 'Auth', requiresAuth: true, hideForAuth:false, showFor:'client', accountStatus:'approved'}
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: () => import('@/views/privacyPolicy.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
    },
  },
]
